// ========================================

//     reserve
//         /reserve/のみで使うスタイル

//     INDEX
//         - main

// ========================================

/* ========================================
    ご予約
======================================== */
.reserve-container {
    padding: 10px 25px;
    @include clearfix;

    @include mq-l() {
        padding: 10px 18px;
    }
    @include mq-m() {
        padding: 10px 12px;
    }
    @include mq-s() {
        padding: 10px 10px;
    }


    &_list {
        @include clearfix;

        li {
            float: left;
            width: 50%;
            max-width: 340px;

            @include mq-s() {
                width: 100%;
                max-width: 600px;
            }

            &:nth-child(2n+1) {
                padding-right: 10px;

                @include mq-s() {
                    padding-right: 0;
                    padding-bottom: 15px;
                }
            }
            &:nth-child(2n) {
                padding-left: 10px;

                @include mq-s() {
                    padding-left: 0;
                }
            }
        }
    }

    &_faq {
        padding-bottom: 20px;
        @include clearfix;

        li {
            float: left;
            width: 50%;

            @include mq-m() {
                width: 100%;
            }
            @include mq-s() {
                width: 100%;
            }

            &:nth-child(2n+1) {
                padding-right: 10px;
                clear: both;

                @include mq-m() {
                    padding-right: 0;
                }
                @include mq-s() {
                    padding-right: 0;
                }
            }
            &:nth-child(2n) {
                padding-left: 10px;

                @include mq-m() {
                    padding-left: 0;
                }
                @include mq-s() {
                    padding-left: 0;
                }
            }

            a {
                display: block;
                width: 100%;
                padding: 4px 5px 4px 20px;
                color: #333;
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    width: 12px;
                    height: 12px;
                    background: url("/images/common/icon-arrow_2.png") center center no-repeat;
                    background-size: 12px;
                    position: absolute;
                    left: 0;
                    top: calc(50% - 6px);
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &_inner {
        @include clearfix;

        p {
            @include mq-l() {
                font-size: 1.3rem;
                line-height: 1.72em;
            }
            @include mq-m() {
                font-size: 1.3rem;
                line-height: 1.72em;
            }
            @include mq-s() {
                font-size: 1.3rem;
                line-height: 1.72em;
            }
        }

        .fl {
            width: 50%;
            padding-right: 20px;

            @include mq-m() {
                width: 100%;
                padding-right: 0;
                padding-bottom: 40px;
            }
            @include mq-s() {
                width: 100%;
                padding-right: 0;
                padding-bottom: 30px;
            }

            .btn-100 {
                margin: 30px auto 0;
            }
        }
        .fr {
            width: 50%;
            padding-left: 20px;

            @include mq-m() {
                width: 100%;
                padding-left: 0;
            }
            @include mq-s() {
                width: 100%;
                padding-left: 0;
            }
        }

        .reserve-tel {
            font-family: $EN_FONT_FAMILY;
            text-align: center;
            font-size: 4.5rem;
            line-height: 1em;
            margin-top: 25px;

            @include mq-m() {
                font-size: 4.2rem;
                line-height: 1em;
            }
            @include mq-s() {
                font-size: 3.8rem;
                line-height: 1em;
            }
        }
        .reserve-time {
            text-align: center;
            font-size: 1.8rem;
            line-height: 1.5em;
            font-weight: bold;
            margin-bottom: 10px;

            @include mq-m() {
                font-size: 1.6rem;
                line-height: 1.5em;
            }
            @include mq-s() {
                font-size: 1.5rem;
                line-height: 1.5em;
            }
        }
        .al-c {
            @include mq-s() {
                text-align: left !important;
            }
        }
    }

    .btn-link {
        display: block;
        border-radius: 5px;
        width: 100%;
        max-width: 400px;
        padding: 4px 10px;
        background: #909090;
        color: #fff;
        border: 1px solid #909090;
        text-align: center;
        margin: 15px 0 5px auto;
        transition: 0.5s;
        
        span {
            &::before {
                content: '';
                display: inline-block;
                width: 20px;
                height: 12px;
                background: url("/images/common/icon-arrow_1.png") left center no-repeat;
                background-size: 12px;
            }
        }

        &:hover,
        &.hover {
            background: $C_WHITE;
            color: $C_BLACK;

            span {
                &::before {
                    background: url("/images/common/icon-arrow_2.png") left center no-repeat;
                    background-size: 12px;
                }
            }
        }
    }
}