// ========================================

//     title
//         見出しのパーツ

//     INDEX
//         - section
//         - main
//         - sub

// ========================================

/* ========================================
    main
        中見出し
======================================== */
.section-title {
    background: url('/images/common/common-bg.png') repeat;
    text-align: center;
    padding: 35px 20px 40px;

    &_top {
        text-align: center;
        padding: 0 20px 40px;
    }

    &_h2 {
        font-size: 2.6rem;
        line-height: 1.5em;
        letter-spacing: 0.1em;
        padding: 0 0 10px 0.1em;
        position: relative;

        @include mq-m() {
            font-size: 2.4rem;
            line-height: 1.5em;
        }
        @include mq-s() {
            font-size: 2.2rem;
            line-height: 1.5em;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            margin: 0 auto;
            width: 100px;
            height: 4px;
            background: $C_SUB1;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    &_text {
        padding-top: 20px;

        @include mq-m() {
            font-size: 1.3rem;
            line-height: 1.7em;
        }
        @include mq-s() {
            font-size: 1.3rem;
            line-height: 1.7em;
            text-align: left;
        }
    }
}

/* ========================================
    sub
        子見出し
======================================== */
.sub-title {
    font-size: 2.6rem;
    line-height: 1.5em;
    padding: 0 25px 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #cecdcd;
    position: relative;

    @include mq-l() {
        font-size: 2.4rem;
        line-height: 1.5em;
        padding: 0 20px 10px;
    }
    @include mq-m() {
        font-size: 2.2rem;
        line-height: 1.5em;
        padding: 0 15px 10px;
        margin-bottom: 15px;
    }
    @include mq-s() {
        font-size: 2rem;
        line-height: 1.5em;
        padding: 0 10px 10px;
        margin-bottom: 10px;
    }

    &::after {
        content: '';
        display: block;
        background: #cecdcd;
        width: 130px;
        height: 4px;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

/* ========================================
    accent
        子子見出し
======================================== */
.accent-title {
    font-size: 1.8rem;
    line-height: 1.5em;
    font-weight: bold;
    padding: 0 2px 5px;
    margin-bottom: 10px;
    border-bottom: 3px double #cecdcd;

    @include mq-m() {
        font-size: 1.7rem;
        line-height: 1.5em;
    }
    @include mq-s() {
        font-size: 1.6rem;
        line-height: 1.5em;
    }
}