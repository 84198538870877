// ========================================

//     info
//         /info/のみで使うスタイル

//     INDEX
//         - main

// ========================================

/* ========================================
    info list
======================================== */
.info-fl {

}
.info-fr {
    @include clearfix;
    padding-bottom: 40px;

    li {
        float: left;
        width: 25%;
        padding: 10px;

        &:nth-child(4n) {
            padding: 10px 0 0 12px;

            @include mq-m() {
                padding: 0;
            }
        }
        &:nth-child(4n+1) {
            padding: 10px 12px 0 0;

            @include mq-m() {
                padding: 0;
            }
            @include mq-s() {
                padding: 0;
            }
        }
        &:nth-child(4n+2) {
            padding: 10px 8px 0 4px;

            @include mq-m() {
                padding: 0;
            }
            @include mq-s() {
                padding: 0;
            }
        }
        &:nth-child(4n+3) {
            padding: 10px 4px 0 8px;

            @include mq-m() {
                padding: 0;
            }
            @include mq-s() {
                padding: 0;
            }
        }

        @include mq-m() {
            width: 33.3333%;

            &:nth-child(3n) {
                padding: 10px 0 0 14px;
            }
            &:nth-child(3n+1) {
                padding: 10px 14px 0 0;
            }
            &:nth-child(3n+2) {
                padding: 10px 7px;
            }
        }
        @include mq-s() {
            width: 50%;

            &:nth-child(2n) {
                padding: 10px 0 0 8px;
            }
            &:nth-child(2n+1) {
                padding: 10px 8px 0 0;
            }
        }
    }
}


.change-panel {
    display: none;
    position: relative;
    z-index: 99;

    &_btn {
        height: auto;
        font-size: 1.4rem;
        line-height: 1.25em;
        color: #333;
        border: 1px solid #ddd;
        background: #fff;
        padding: 12px 10px 12px 35px;
        border-radius: 5px;
        transition: 0.5s;
        position: relative;
        width: 100%;
        margin: 0;
        text-align: left;
        cursor: pointer;

        &:before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: url("/images/common/icon-arrow_2.png") center center no-repeat;
                background-size: auto auto;
            background-size: 12px;
            position: absolute;
            left: 10px;
            top: calc(50% - 6px);
        }

        &:hover,
        &.hover,
        &.is-active {
            background: #ddd;
            transition: 0.5s;
        }
    }
}


.topics-list {
    li {
        border-top: 1px dashed $C_BLACK;
        padding: 20px 30px;
        @include clearfix;

        @include mq-l() {
            padding: 20px 23px;
        }
        @include mq-m() {
            padding: 20px 15px;
        }
        @include mq-s() {
            padding: 20px 10px;
        }

        &:first-child {
            border-top: 0;
        }

        a {
            display: block;
            width: 100%;
            @include clearfix;
            transition: 0.5s;

            &:hover {
                color: $C_LINK !important;
            }
        }
    }

    .topics-date {
        float: left;
        width: 12%;
        font-family: $EN_FONT_FAMILY;
        font-weight: bold;

        @include mq-m() {
            font-size: 1.6rem;
            line-height: 1.72em;
            padding-bottom: 4px;
            float: none;
            width: 100%;
        }
        @include mq-s() {
            font-size: 1.6rem;
            line-height: 1.72em;
            padding-bottom: 4px;
            float: none;
            width: 100%;
        }
    }
    .topics-detail {
        float: right;
        width: 88%;
        padding-left: 20px;

        @include mq-m() {
            float: none;
            width: 100%;
            padding-left: 0;
        }
        @include mq-s() {
            float: none;
            width: 100%;
            padding-left: 0;
        }

        &_title {
            @include clearfix;
            padding-bottom: 10px;

            &-cat {
                width: 80px;
                text-align: center;
                background: #7d7d7d;
                color: $C_WHITE;
                padding: 2px 5px;
                border-radius: 5px;
                float: left;
            }
            &-text {
                padding: 2px 0 0 10px;
                font-weight: bold;
                float: right;
                width: calc(100% - 80px);
                word-wrap: break-word;
            }
        }

        &_body {
            word-wrap: break-word;

            a {
                display: inline-block;
                color: $C_BLACK;
                text-decoration: underline;

                &:hover {
                    color: $C_LINK;
                    text-decoration: none;
                }
            }
        }
    }
}

//モーダル内 記事詳細
.topics-item {
    background: $C_WHITE;
    width: 80vw;
    max-width: 1200px;
    height: 90vh;
    max-height: 800px!important;
    padding: 30px;
    overflow-x: hidden!important;
    overflow-y: auto!important;
    margin: 0 auto;

    @include mq-l() {
        padding: 20px;
    }
    @include mq-m() {
        padding: 15px;
        width: 90vw;
        height: 70vh;
    }
    @include mq-s() {
        padding: 10px;
        width: 95vw;
        height: 68vh;
    }

    .accent-title {
        padding: 0 8px 5px;
    }

    img {
        width: auto;
        max-width: 80%;
        margin: 0 auto 30px;
        display: block;

        @include mq-m() {
            max-width: 90%;
            margin: 0 auto 20px;
        }
        @include mq-s() {
            max-width: 90%;
            margin: 0 auto 15px;
        }
    }

    &_date {
        font-family: $EN_FONT_FAMILY;
        font-weight: bold;
        display: block;
        font-size: 1.6rem;
        line-height: 1.5em;
        padding: 0 10px 4px;
    }

    &_title {
        font-size: 2rem;
        line-height: 1.5em;
        padding: 0 10px 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid #cecdcd;
        position: relative;
        word-wrap: break-word;

        @include mq-l() {
            font-size: 1.8rem;
            line-height: 1.5em;
        }
        @include mq-m() {
            font-size: 1.6rem;
            line-height: 1.5em;
        }
        @include mq-s() {
            font-size: 1.5rem;
            line-height: 1.5em;
        }

        &::after {
            content: '';
            display: block;
            background: #cecdcd;
            width: 100px;
            height: 4px;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    &_inner {
        padding: 0 10px 30px;
        word-wrap: break-word;

        @include mq-l() {
            padding: 0 10px 20px;
        }
        @include mq-m() {
            font-size: 1.3rem;
            line-height: 1.7em;
            padding: 0 10px 20px;
        }
        @include mq-s() {
            font-size: 1.3rem;
            line-height: 1.7em;
            padding: 0 10px 20px;
        }
    }

    &_links {
        padding: 0 0 30px;

        @include mq-l() {
            padding: 0 0 20px;
        }
        @include mq-m() {
            font-size: 1.3rem;
            line-height: 1.7em;
            padding: 0 0 15px;
        }
        @include mq-s() {
            font-size: 1.3rem;
            line-height: 1.7em;
            padding: 0 0 10px;
        }

        li {
            position: relative;
            margin-bottom: 5px;

            a {
                height: auto;
                font-size: 1.4rem;
                line-height: 1.25em;
                color: $C_BLACK;
                background: $C_WHITE;
                padding: 12px 10px 12px 35px;
                border-radius: 5px;
                transition: 0.5s;
                width: 100%;
                display: block;

                &:before {
                    content: '';
                    display: block;
                    width: 12px;
                    height: 12px;
                    background: url('/images/common/icon-arrow_2.png') center center no-repeat;
                    background-size: 12px;
                    position: absolute;
                    left: 10px;
                    top: calc(50% - 6px);
                }

                &:hover,
                &.hover {
                    background: #f5f3f3;
                    transition: 0.5s;
                }
            }
        }
    }
}