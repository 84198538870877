// ========================================

//     facility
//         /facility/のみで使うスタイル

//     INDEX
//         - main

// ========================================

/* ========================================
    医師紹介＆施設紹介
======================================== */
.facility-container {
    padding: 10px 25px;
    @include clearfix;

    @include mq-l() {
        padding: 5px 18px;
    }
    @include mq-m() {
        padding: 5px 12px;
    }
    @include mq-s() {
        padding: 5px 10px;
    }

    &_img {
        float: right;
        width: 35%;

        @include mq-m() {
            float: none;
            width: 100%;
            padding: 0 0 20px;
        }
        @include mq-s() {
            float: none;
            width: 100%;
            padding: 0 0 20px;
        }
    }

    &_text {
        float: left;
        width: 65%;
        padding-right: 30px;

        @include mq-m() {
            float: none;
            width: 100%;
            padding: 0;
        }
        @include mq-s() {
            float: none;
            width: 100%;
            padding: 0;
        }

        .facility-name {
            font-size: 2.4rem;
            line-height: 1.5em;
            padding-bottom: 10px;
            font-weight: bold;

            @include mq-l() {
                font-size: 2.2rem;
                line-height: 1.5em;
            }
            @include mq-m() {
                font-size: 2rem;
                line-height: 1.5em;
            }
            @include mq-s() {
                font-size: 1.8rem;
                line-height: 1.5em;
            }

            .f-name {
                display: inline-block;
                padding-left: 10px;
                font-size: 1.5rem;
                line-height: 1.5em;
                color: #ab7123;
                font-family: $EN_FONT_FAMILY;
            }
        }

        dt {
            width: 20%;
            background: $C_SUB2;
            color: $C_WHITE;
            text-align: center;
            border-radius: 100px;
            float: left;

            @include mq-s() {
                width: 30%;
            }
        }
        dd {
            width: 80%;
            float: right;
            padding-left: 30px;

            @include mq-s() {
                width: 70%;
                padding-left: 15px;
            }
        }

        .cf {
            padding-bottom: 15px;
        }
    }

    &_list {
        @include clearfix;

        li {
            float: left;
            width: 33.3333%;

            @include mq-m() {
                width: 50%;
            }
            @include mq-s() {
                width: 100%;
                padding: 0 10px 10px;
            }

            &:nth-child(1),
            &:nth-child(4),
            &:nth-child(7) {
                padding: 0 20px 20px 0;

                @include mq-l() {
                    padding: 0 12px 12px 0;
                }
                @include mq-m() {
                    padding: 0;
                }
                @include mq-s() {
                    padding: 0 0 10px;
                }
            }
            &:nth-child(2),
            &:nth-child(5),
            &:nth-child(8) {
                padding: 0 10px 20px;

                @include mq-l() {
                    padding: 0 6px 12px;
                }
                @include mq-m() {
                    padding: 0;
                }
                @include mq-s() {
                    padding: 0 0 10px;
                }
            }
            &:nth-child(3),
            &:nth-child(6),
            &:nth-child(9) {
                padding: 0 0 20px 20px;

                @include mq-l() {
                    padding: 0 0 12px 12px;
                }
                @include mq-m() {
                    padding: 0;
                }
                @include mq-s() {
                    padding: 0 0 10px;
                }
            }

            @include mq-m() {
                &:nth-child(2n+1) {
                    padding: 0 10px 5px 0;
                }
                &:nth-child(2n) {
                    padding: 0 0 5px 10px;
                }
            }
        }

        .facility-img {
            padding-bottom: 10px;

            @include mq-m() {
                padding-bottom: 5px;
            }
            @include mq-s() {
                padding-bottom: 5px;
            }
        }
        .facility-cat {
            @include mq-m() {
                padding-bottom: 4px;
            }
        }

        .facility-name {
            font-size: 2.3rem;
            line-height: 1.5em;
            padding-bottom: 10px;
            font-weight: bold;

            @include mq-l() {
                font-size: 1.8rem;
                line-height: 1.5em;
            }
            @include mq-m() {
                font-size: 2rem;
                line-height: 1em;
                letter-spacing: 0.1em;
            }
            @include mq-s() {
                font-size: 2rem;
                line-height: 1em;
                letter-spacing: 0.1em;
            }

            .f-name {
                display: inline-block;
                padding-left: 10px;
                font-size: 1.5rem;
                line-height: 1.5em;
                color: #ab7123;
                font-family: $EN_FONT_FAMILY;

                @include mq-l() {
                    font-size: 1.3rem;
                    line-height: 1.5em;
                }
                @include mq-m() {
                    display: block;
                    padding: 0;
                    font-size: 1.3rem;
                    line-height: 1.5em;
                    letter-spacing: 0.05em;
                }
                @include mq-s() {
                    // display: block;
                    // padding: 0;
                    padding-left: 5px;
                    font-size: 1.3rem;
                    line-height: 1.5em;
                    letter-spacing: 0.05em;
                }
            }
        }
        .machine-name {
            font-size: 1.5rem;
            line-height: 1.5em;
            padding-bottom: 10px;
            font-weight: bold;
        }
    }

    .clinic-map {
        margin: 15px auto;
        position: relative;
        width: 100%;
        padding-top: 40%;
        border-radius: 10px;
        overflow: hidden;

        iframe{
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
        }
    }

    &_img-2 {
        float: left;
        width: 35%;

        @include mq-m() {
            float: none;
            width: 100%;
            padding: 0 0 20px;
        }
        @include mq-s() {
            float: none;
            width: 100%;
            padding: 0 0 20px;
        }
    }
    &_text-2 {
        float: right;
        width: 65%;
        padding-left: 30px;

        @include mq-m() {
            float: none;
            width: 100%;
            padding: 0;
        }
        @include mq-s() {
            float: none;
            width: 100%;
            padding: 0;
        }


        dt {
            width: 40%;
            background: $C_SUB2;
            color: $C_WHITE;
            text-align: center;
            border-radius: 100px;
            margin-bottom: 8px;
        }
        .btn-100 {
            margin: 0 auto 0 0;
        }
    }
}


/* ========================================
    クリニックについて
======================================== */
.clinic-container {
    padding: 10px 25px 50px;
    @include clearfix;

    @include mq-l() {
        padding: 10px 18px  40px;
    }
    @include mq-m() {
        padding: 10px 12px 30px;
    }
    @include mq-s() {
        padding: 10px 10px 25px;
    }

    &_img {
        float: left;
        width: 35%;

        @include mq-m() {
            float: none;
            width: 100%;
            padding-top: 15px;
        }
        @include mq-s() {
            float: none;
            width: 100%;
            padding-top: 15px;
        }
    }
    &_detail {
        float: right;
        width: 65%;
        padding-left: 30px;

        @include mq-m() {
            float: none;
            width: 100%;
            padding: 0;
        }
        @include mq-s() {
            float: none;
            width: 100%;
            padding: 0;
        }

        .clinic-title {
            font-size: 1.8rem;
            line-height: 1.4em;
            font-weight: bold;
            padding: 0 2px 8px;
            margin-bottom: 10px;
            border-bottom: 3px double #cecdcd;
            @include clearfix;


            %clinic-cat {
                float: right;
                display: inline-block;
                font-size: 1.4rem;
                line-height: 1em;
                background: $C_BLACK;
                color: $C_WHITE;
                padding: 5px 10px;
                border-radius: 100px;
                margin-left: 10px;
            }

            span {
                &.clinic-name {
                    display: inline-block;
                    float: left;

                    @include mq-m() {
                        width: 65%;
                    }
                    @include mq-s() {
                        float: none;
                        width: 100%;
                    }
                }

                &.clinic-cat-1 {
                    @extend %clinic-cat;
                    background: $C_PINK;

                    @include mq-s() {
                        float: none;
                        text-align: left;
                        margin: 6px 2px 0 0;
                    }
                }
                &.clinic-cat-2 {
                    @extend %clinic-cat;
                    background: $C_BLUE;

                    @include mq-s() {
                        float: none;
                        text-align: left;
                        margin: 6px 2px 0 0;
                    }
                }
                &.clinic-cat-3 {
                    @extend %clinic-cat;
                    background: $C_GREEN;

                    @include mq-s() {
                        float: none;
                        text-align: left;
                        margin: 6px 2px 0 0;
                    }
                }
            }
        }

        .clinic-tel {
            width: 100%;
            margin: 0 auto 20px;
            border-radius: 6px;
            border: 1px solid #ccc;
            display: table;
            vertical-align: middle;
            table-layout: fixed;

            @include mq-s() {
                display: block;
            }

            &_title {
                display: table-cell;
                vertical-align: middle;
                width: 30%;
                padding: 15px;
                border-right: 1px solid #ccc;
                text-align: center;
                font-size: 1.5rem;
                line-height: 1em;
                background: #f6f6f6;
                border-radius: 6px 0 0 6px;

                @include mq-s() {
                    display: block;
                    width: 100%;
                    border-radius: 6px 6px 0 0;
                    border-right: 0;
                    border-bottom: 1px solid #ccc;
                    text-align: left;
                }
            }

            &_number {
                display: table-cell;
                vertical-align: middle;
                width: 70%;
                padding: 15px 20px;
                font-family: $EN_FONT_FAMILY;
                font-size: 1.8rem;
                font-weight: bold;
                line-height: 1em;
                letter-spacing: 0.1em;

                @include mq-s() {
                    display: block;
                    width: 100%;
                    padding: 15px;
                }

                &-gym {
                    line-height: 1.5em;
                    padding: 12px 15px;
                }
            }
        }

        .clinic-info {
            dt {
                width: 25%;
                background: #623a35;
                color: #fff;
                text-align: center;
                border-radius: 100px;
                float: left;
                
                @include mq-s() {
                    width: 60%;
                    float: none;
                }
            }
            dd {
                width: 75%;
                float: right;
                padding-left: 30px;

                @include mq-l() {
                    padding-left: 15px;
                }
                @include mq-m() {
                    padding-left: 15px;
                }
                @include mq-s() {
                    padding-left: 0;
                    width: 100%;
                    float: none;
                }
            }

            .cf {
                padding: 5px 0;
            }
        }

        .clinic-map {
            margin: 15px auto;
            position: relative;
            width: 100%;
            padding-top: 40%;
            border-radius: 10px;
            overflow: hidden;

            iframe{
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
            }
        }

        .clinic-link {
            @include clearfix;

            li {
                float: left;
                width: 50%;

                @include mq-s() {
                    float: none;
                    width: 100%;
                }

                &:nth-child(2n+1) {
                    padding-right: 10px;

                    @include mq-s() {
                        padding: 0 0 5px;
                    }
                }
                &:nth-child(2n) {
                    padding-left: 10px;

                    @include mq-s() {
                        padding-left: 0;
                    }
                }

                .btn-100 {
                    max-width: 500px;
                }
            }
        }
    }
}


/* ========================================
    外来
======================================== */
.facility-table {
    width: 100%;
    margin: 10px 0;
    border: 1px solid $C_BLACK;

    tr {
        &:nth-child(2n+1) {
            background: #edecec;
        }
    }

    th {
        padding: 18px 30px;
        width: 26%;
        border-right: 1px solid $C_BLACK;

        @include mq-l() {
            width: 20%;
        }
        @include mq-m() {
            display: block;
            width: 100%;
            border-right: 0;
            float: left;
            font-weight: bold;
            font-size: 1.6rem;
            line-height: 1.5em;
            padding: 15px 20px 5px;
            
            &:before {
                content: '■ ';
            }
        }
        @include mq-s() {
            display: block;
            width: 100%;
            border-right: 0;
            float: left;
            font-weight: bold;
            font-size: 1.6rem;
            line-height: 1.5em;
            padding: 15px 20px 5px;
            
            &:before {
                content: '■ ';
            }
        }
    }

    td {
        padding: 13px 30px;

        @include mq-m() {
            display: block;
            width: 100%;
            float: left;
            padding: 5px 18px 15px;
        }
        @include mq-s() {
            display: block;
            width: 100%;
            float: left;
            padding: 5px 18px 15px;
        }

        span {
            display: inline-block;
            background: $C_SUB1;
            color: $C_WHITE;
            padding: 2px 10px;
            text-align: center;
            width: 20%;
            margin: 5px 10px 5px 0;
            border-radius: 100px;

            @include mq-m() {
                width: 32%;
            }
            @include mq-s() {
                width: 38%;
            }
        }

        .small {
            span {
                width: 10%;
            }
        }

        a {
            margin: 0 auto 0 0;

            @include mq-s() {
                margin: 0 auto;
            }
        }
    }
}


.facility-tab {
    @include clearfix;

    &1,
    &2,
    &3 {
        float : left;
        width: 32.333333%;
        border-radius: 5px 5px 0 0;
        padding: 20px 10px; 
        background: $C_WHITE;
        border-top: 1px solid $C_GRAY;
        border-right: 1px solid $C_GRAY;
        border-left: 1px solid $C_GRAY;
        color: $C_BLACK;
        text-align: center;
        transition: 0.5s;
        cursor: pointer;
        font-weight: bold;
        line-height: 1.2;

        @include mq-s() {
            font-size: 1.3rem;
            line-height: 1.2;
            padding: 12px 10px; 
        }

        p {
            display: inline-block;
    
            &:before {
                content: '';
                display: inline-block;
                width: 14px;
                height: 12px;
                background: url("/images/common/icon-arrow_2.png") left center no-repeat;
                background-size: 12px;
            }
        }
    }
    &1 {
        margin-right: 1%;
    }
    &3 {
        margin-left: 1%;
    }
    &2 {
        margin: 0 0.5%;
    }
}
.tab_current {
    background : $C_GRAY;
}

.facility-content {
    &1,
    &2,
    &3 {
        display : none;
        border: 1px solid $C_GRAY;
        padding : 30px 20px 20px;
        width : 100%;
    }

    .btn-100 {
        margin: 0 auto 0 0;

        @include mq-s() {
            margin: 0 auto;
        }
    }
}
.content_current {
    display : block;
}

.p-list {
    padding-left:1em;
    text-indent:-1em;
}


.grad-wrap {
    position: relative;

    & + .grad-wrap {
        margin-top: 40px;
    }
}

.grad-item {
    position: relative;
    overflow: hidden;

    &.is-hide {
        height: 140px; /*隠した後の高さ*/
    }

    &:before {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 60px; /*グラデーションで隠す高さ*/
        background: linear-gradient(to bottom, rgba(251,251,251,0) 0%,rgba(251,251,251,1) 90%,rgba(251,251,251,1) 100%);
    }
}

.grad-trigger {
    z-index: 2;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 320px;
    margin: auto;
    padding: 10px;
    border-radius: 5px;
    background: $C_WHITE;
    border: 1px solid $C_GRAY;
    color: $C_BLACK;
    font-size: 1.3rem;
    text-align: center;
    cursor: pointer;
    transition: 0.3s ease;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);

    .fas {
        margin-right: 4px;
    }

    &:hover {
        background: $C_GRAY;
    }

    &:after {
        content: "続きを読む"
    }

    &.is-show {

        .fas {
            transform: rotate(180deg);
        }

        &:after {
            content: "閉じる"
        }
        & + .grad-item::before {
            display: none;
        }
    }
}


