// ========================================

//     btn
//         ボタンのスタイル

//     INDEX
//         - class
//         - normal
//         - special

// ========================================

/* ========================================
  class
======================================== */
// 一番普通のボタンの共通スタイル
%normal-btn {
    border-radius: 5px;
    width: 100%;
    max-width: 320px;
    padding: 18px 10px;
    background: $C_WHITE;
    border: 1px solid $C_GRAY;
    color: $C_BLACK;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    transition: 0.5s;
    cursor: pointer;

    &:hover,
    &.hover {
        background: $C_GRAY;
        color: $C_WHITE;
    }

    p {
        display: inline-block;

        &:before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 12px;
            background: url("/images/common/icon-arrow_2.png") left center no-repeat;
            background-size: 12px;
        }
    }
}

// 目立たせたいボタンの共通スタイル
%special-btn {
    background-color: red;
    color: white;
}

// 幅いっぱいのサイズ
%btn-100 {
    display: block;
    width: 100%;
}

// ２つ並べられるサイズ
%btn-50 {
    display: inline-block;
    width: 49%;
}

// ちっちゃいサイズ
%btn-30 {
    display: block;
    width: 30%;
}


/* ========================================
    normal
======================================== */
.btn-100 {
    @extend %normal-btn;
    @extend %btn-100;

    &.disabled {
        opacity: 0.4;
        cursor: default;

        &:hover,
        &.hover {
            background: $C_WHITE;
            color: $C_BLACK;
        }
    }
}

.btn-none {
    @extend %normal-btn;
    @extend %btn-100;
    background: #f0f0f0;
    border: 1px solid $C_GRAY;
    color: #999;

    &.disabled {
        opacity: 0.4;
        cursor: default;

        &:hover,
        &.hover {
            background: #f0f0f0;
            color: #999;
        }
    }
}

button.btn-100,
button.btn-none {
    font-size: 1em;
    line-height: 1.72em;

    &:disabled {
        opacity: 0.4;
        cursor: default;

        &:hover,
        &.hover {
            background: $C_WHITE;
            color: $C_BLACK;
        }
    }
}


.btn-50 {
    @extend %normal-btn;
    @extend %btn-50;
}

.btn-30 {
    $this: &;
    @extend %normal-btn;
    @extend %btn-30;
    margin: auto;

    // 右寄せ
    &--right {
        @extend #{$this};
        margin-right: 0;
        margin-left: auto;
    }

    // 左寄せ
    &--left {
        @extend #{$this};
        margin-right: auto;
        margin-left: 0;
    }
}


/* ========================================
    special
======================================== */
.btn-special-100 {
    @extend %special-btn;
    @extend %btn-100;
}

.btn-special-50 {
    @extend %special-btn;
    @extend %btn-50;
}