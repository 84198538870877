// ========================================

//     faq
//         /faq/のみで使うスタイル

//     INDEX
//         - main

// ========================================

/* ========================================
    FAQ
======================================== */
.faq_list {
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 20px;

    > dt {
        background: #f6f6f6;
        border-radius: 5px;
        width: 100%;
        padding: 15px 45px;
        position: relative;

        &:before {
            content: "Q";
            display: block;
            position: absolute;
            top: 14px;
            left: 15px;
            font-family: $EN_FONT_FAMILY;
            color: $C_BLUE;
            font-size: 2.5rem;
            line-height: 1em;
        }

        &:after {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            position: absolute;
            top: calc( 50% - 8px);
            right: 15px;
            background-image: url("/images/common/icon-arrow_o.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
        }
        &.open {
            border-radius: 5px 5px 0 0;
            //border-bottom: 1px solid #ccc;

            &:after {
                background-image: url("/images/common/icon-arrow_c.png");
            }
        }
    }
    > dd {
        padding: 20px 30px 20px 45px;
        position: relative;
        border-top: 1px solid #ccc;

        @include mq-s() {
            padding: 20px 20px 20px 45px;
        }

        &:before {
            content: "A";
            display: block;
            position: absolute;
            top: 20px;
            left: 15px;
            font-family: $EN_FONT_FAMILY;
            color: $C_RED;
            font-size: 2.5rem;
            line-height: 1em;
        }

        a {
            display: inline-block;
            color: $C_LINK;
            text-decoration: underline;
        }
    }

    &-flow {
        margin-left: 20px;

        li {
            list-style-type: decimal;
            padding: 5px 0;
        }
    }
    &-att {
        margin-left: 20px;

        li {
            list-style-type: disc;
        }
    }
}