// ========================================

//     pager
//         ページャーのパーツ

//     INDEX
//         - pager

// ========================================

/* ========================================
    pager
======================================== */
.pager {
    // 戻る
    .btn-back {
    }

    // 一覧へ
    .btn-list {
    }
    
    // 次へ
    .btn-next {
    }

    // 番号付きページング
    .btn-num {
        &__item {
            &.current {
            }
        }
    }

    // ボタンの打ち消し
    .btn-none {
        pointer-events: none;
    }
}


/* ========================================
    Breadcrumb
======================================== */
.section-breadcrumb {
    width: 100%;
    max-width: 1300px;
    padding: 5px 50px;
    margin: 0 auto;
    text-align: right;
    font-size: 1.2rem;
    line-height: 1.5em;
    color: #666;
    position: relative;
    z-index: 99;

    @include mq-l() {
        padding: 5px 30px;
    }
    @include mq-m() {
        padding: 5px 30px;
    }
    @include mq-s() {
        padding: 5px 20px;
    }

    li {
        display: inline-block;

        &:before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f0da";

            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            padding: 0 5px 0 3px;
        }

        &:first-child {
            &:before {
                display: none;
            }
        }
    }

    a {
        color: #666;
        text-decoration: underline;
    }
}

