// ========================================
//  aem scss templates
//      ver 2.0.4
// ======================================== 
@charset 'UTF-8';

/* ========================================
    common
        サイト全体の設定、定義など
======================================== */
@import "common/variables";
@import "common/mixins";
@import "common/reset";
@import "common/base";
@import "common/parts";
@import "common/animation";

/* ========================================
    components
        共通で使うパーツを書くスタイル
======================================== */
@import "components/common";
@import "components/header";
@import "components/footer";
@import "components/sns";
@import "components/nav";
@import "components/pager";
@import "components/title";
@import "components/btn";
@import "components/list";

// タブレット以上の場合のみホバーを適応
@media screen and (min-width: $BP_M) {
    @import "components/hover";
}


/* ========================================
    pages
        ページごとに独立するスタイル
======================================== */
@import "pages/index";
@import "pages/about";
@import "pages/facility";
@import "pages/faq";
@import "pages/news";
@import "pages/form";
@import "pages/reserve";
@import "pages/other";

body.bclose,
.close-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &__header {
        padding: 30px 10px;
        text-align: center;

        img {
            max-width: 290px;
            margin: 0 auto;
            width: 50%;
        }
    }
    &__footer {
        margin-top: auto;
        background: #4f2a26;
        color: #fff;
        text-align: center;
        padding: 15px 20px;
        font-size: 1.2rem;
    }
}