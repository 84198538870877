// ========================================

//     nav
//         ナビゲーションのパーツ

//     INDEX
//         - global
//         - trigger
//         - sub
//         - pnkz
//         - members menu

// ========================================

/* ========================================
    global
======================================== */
.global-link {
    @include clearfix;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 999;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    @include mq-m() {
        position: inherit;
    }
    @include mq-s() {
        position: inherit;
    }
}

.global-menu {
    width: 100%;
    text-align: center;
    background: rgba(255, 255, 255, 0.8);
    clear: both;
    @include clearfix;

    @include mq-m() {
        display: none;
    }
    @include mq-s() {
        display: none;
    }

    &_inner {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
    }

    li {
        float: left;
        width: 25%;
        border-right: 1px solid $C_GRAY;
        position: relative;

        &:first-child {
            border-left: 1px solid $C_GRAY;
        }

        a {
            display: block;
            width: 100%;
            height: 80px;
            font-size: 2rem;
            line-height: 80px;
            color: $C_BLACK;
            transition: 0.5s;
            position: relative;

            @include mq-l() {
                height: 65px;
                font-size: 1.7rem;
                line-height: 65px;
            }

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                background: $C_WHITE;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 2;
                transition: 0.5s;
            }

            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                background: $C_SUB1;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
            }

            &:hover {
                color: $C_SUB1;
                transition: 0.5s;

                &:before {
                    opacity: 0;
                    transition: 0.5s;
                }
            }
        }
    }

    .child {
        display: none;
        position: absolute;
        padding: 10px;
        background: $C_WHITE;
        border: 1px solid $C_GRAY;
        width: calc(100% + 2px);
        margin-left: -1px;
        z-index: 120;

        li {
            display: block;
            float: none;
            width: 100%;
            border-right: 0;
            text-align: left;

            &:first-child {
                border-left: 0;
            }

            a {
                height: auto;
                font-size: 1.4rem;
                line-height: 1.25em;
                color: $C_BLACK;
                background: $C_WHITE;
                padding: 12px 10px 12px 35px;
                border-radius: 5px;
                transition: 0.5s;

                &:before {
                    content: '';
                    display: block;
                    width: 12px;
                    height: 12px;
                    background: url('/images/common/icon-arrow_2.png') center center no-repeat;
                    background-size: 12px;
                    position: absolute;
                    left: 10px;
                    top: calc(50% - 6px);
                }

                &:after {
                    display: none;
                }

                &:hover {
                    background: #f5f3f3;
                    transition: 0.5s;

                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
    }
}


/* ========================================
    sp
======================================== */
.button_container {
    //position: fixed;
    position: absolute;
    top: 28px;
    right: 20px;
    height: 24px;
    width: 30px;
    cursor: pointer;
    z-index: 100;
    transition: opacity 0.3s ease;
    z-index: 9999;

    span {
        background: $C_BLACK;
        border: none;
        height: 4px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition:  all .35s ease;
        cursor: pointer;
        
        &:nth-of-type(2) {
            top: 10px;
        }
        
        &:nth-of-type(3) {
            top: 20px;
        }
    }

    &:hover {
      opacity: 0.9;
    }

    &.active {
        position: fixed;

        .top {
            transform: translateY(10px) translateX(0) rotate(45deg);
            background: $C_WHITE;
        }
        .middle {
            opacity: 0;
            background: $C_WHITE;
        }
        .bottom {
            transform: translateY(-10px) translateX(0) rotate(-45deg);
            background: $C_WHITE;
        }
    }
}

.overlay {
    position: fixed;
    background: #2c0a06;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    overflow: hidden;
    z-index: 9990;
    color: $C_WHITE;
    
    &.open {
        opacity: 0.95;
        visibility: visible;
        height: 100%;
    }
    nav {
        position: relative;
        height: 100%;
        top: 0;
        padding: 75px 20px 20px;
        overflow-y: scroll;
    }

    &_sublist {
        @include clearfix;
        width: 100%;
        margin: 30px auto;

        li {
            display: block;
            //float: left;
            //width: 50%;
            width: 100%;
            text-align: center;
            border: 1px solid $C_WHITE;

            a {
                display: block;
                width: 100%;
                color: $C_WHITE;
                padding: 14px 0;
            }
        }
    }
}


#acMenu {
    > dt {
        display:block;
        width: 100%;
        border-top: 1px solid $C_WHITE;
        padding: 10px 5px;
        position: relative;

        &:after {
            content: '+';
            position: absolute;
            line-height: 0;
            right: 5px;
            top: 50%;
        }

        &.last-c {
            border-bottom: 1px solid $C_WHITE;
        }
        &.active {
            &:after {
                content: '-';
            }
        }
    }
    > dd {
        width: 100%;
        display:none;
        padding: 15px 0;
        border-top: 1px solid $C_WHITE;

        &.last-c {
            border-top: 0;
        }

        ul {
            @include clearfix;
        }
        li {
            display: block;
            float: left;
            width: 50%;

            &.w100 {
                width: 100%;
            }
        }
        a {
            display: block;
            width: 100%;
            padding: 5px 0 5px 15px;
            position: relative;
            color: $C_WHITE;

            &::before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 12px;
                width: 10px;
                height: 10px;
                background: url('/images/common/icon-arrow_1.png') center center no-repeat;
                background-size: 10px;
            }
        }
    }
}

.acMenu_o {
    overflow: hidden;
}