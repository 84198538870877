// ========================================
    
//     header
//         ヘッダーのパーツ

//     INDEX
//         - common

// ========================================

/* ========================================
    common
======================================== */
.header {
    border-bottom: 1px solid $C_GRAY;
    background: url('/images/common/common-bg.png') repeat;
    padding-top: 60px;
    @include clearfix;

    @include mq-l() {
        padding-top: 70px;
    }
    @include mq-m() {
        padding-top: 0;
    }
    @include mq-s() {
        padding-top: 0;
    }

    &_logo {
        float: left;
        width: 50%;
        padding-left: 30px;
        background: $C_WHITE;

        @include mq-l() {
            padding-left: 20px;
            width: 35%;
        }
        @include mq-m() {
            float: none;
            width: 100%;
            text-align: center;
            padding-left: 0;
        }
        @include mq-s() {
            float: none;
            width: 100%;
            text-align: center;
            padding-left: 0;
        }

        a {
            display: block;
            color: $C_BLACK;
            height: 60px;
            line-height: 60px;
            font-size: 1.3rem;

            @include mq-l() {
                height: 70px;
                line-height: 1em;
                padding-top: 10px;
            }
            @include mq-m() {
                width: 145px;
                height: auto;
                line-height: 1em;
                padding: 12px 0;
                margin: 0 auto;
            }
            @include mq-s() {
                width: 145px;
                height: auto;
                line-height: 1em;
                padding: 12px 0;
                margin: 0 auto;
            }
        }

        img {
            display: inline-block;
            vertical-align: middle;
        }
        
        span {
            @include mq-l() {
                display: block;
                font-size: 1.2rem;
                padding-left: 8px;
            }
            @include mq-m() {
                display: none;
            }
            @include mq-s() {
                display: none;
            }
        }
    }

    &_link {
        float: right;
        width: 50%;
        @include clearfix;
        background: $C_WHITE;

        @include mq-l() {
            width: 65%;
        }
        @include mq-m() {
            display: none;
        }
        @include mq-s() {
            display: none;
        }

        &-list {
            float: right;
            font-size: 0;

            li {
                display: inline-block;
                text-align: center;

                a {
                    display: block;
                    font-size: 1.5rem;
                    height: 60px;
                    line-height: 60px;
                    font-weight: 600;
                    letter-spacing: 0.08em;
                    color: #666;
                    padding: 0 15px;
                    transition: 0.5s;

                    @include mq-l() {
                        height: 70px;
                        line-height: 70px;
                    }

                    &:hover {
                        color: $C_BLACK;
                        background: #f5f3f3;
                    }
                }
            }

            .child {
                display: none;
                position: absolute;
                padding: 10px;
                background: #fff;
                border: 1px solid #ccc;
                width: 220px;
                //margin-left: -55px;
                z-index: 125;

                li {
                    display: block;
                    width: 100%;
                    text-align: left;

                    a {
                        display: block;
                        font-size: 1.3rem;
                        line-height: 1.1em;
                        height: auto;
                        padding: 8px 8px 8px 32px;
                        position: relative;
                        

                        &:before {
                            content: '';
                            display: block;
                            width: 12px;
                            height: 12px;
                            background: url('/images/common/icon-arrow_2.png') center center no-repeat;
                            background-size: 12px;
                            position: absolute;
                            left: 10px;
                            top: calc(50% - 6px);
                        }

                        &:hover {
                            background: #f5f3f3;
                            border-radius: 5px;

                            &:before {
                                opacity: 1;
                            }
                        }
                    }
                    
                }
            }
        }

        &-contact {
            width: 180px;
            float: right;

            @include mq-l() {
                width: 180px;
            }

            a {
                display: block;
                height: 60px;
                text-align: center;
                color: $C_WHITE;
                font-size: 1.6rem;
                font-weight: 600;
                line-height: 60px;
                letter-spacing: 0.05em;
                position: relative;
                z-index: 0;
                transition: 1s;

                @include mq-l() {
                    height: 70px;
                    line-height: 70px;
                }

                &:before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position:absolute;
                    z-index:-1;
                    left: 0;
                    top: 0;
                    transition: 1s;
                    background: $C_SUB1;
                    background: linear-gradient(130deg, $C_SUB2 0%, $C_SUB2 40%, $C_SUB1 40%, $C_SUB1 100%);
                }

                &:after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position:absolute;
                    z-index:-2;
                    left: 0;
                    top: 0;
                    background: $C_SUB2;
                    background: linear-gradient(130deg, $C_SUB2 0%, $C_SUB2 35%, $C_SUB1 45%, $C_SUB1 100%);
                }

                &:hover {
                    &:before {
                        opacity: 0;
                        transition: 1s;
                    }
                }
             }
        }
    }
}
