/* ========================================

    index
        indexページのみで使うスタイル

    INDEX
        - main

======================================== */

// ========================================
//     main
// ========================================
.toppage {
    .header {
        padding-top: 0;
    }
    .global-menu {
        display: none;
    }
}


.topcontents {
    &_main {
        text-align: center;
        position: relative;
        z-index: 10;
        margin-top: 20px;
        
        @include mq-l() {
            margin-top: 40px;
        }
        @include mq-m() {
            margin-top: -5px;
        }
        @include mq-s() {
            margin-top: -5px;
        }

        img {
            width: 100%;
        }

        &-text {
            position: absolute;
            text-align: center;
            margin: 0 auto;
            left: 0;
            right: 0;
            top: 34%;
            padding: 26px 30px;
            background: rgba(255, 255, 255, 0.85);

            @media screen and (min-width: 1300px) {
                top: 36%;
                padding: 25px 30px;
            }
            @include mq-l() {
            }
            @include mq-m() {
                top: 40%;
                padding: 15px 20px;
            }
            @include mq-s() {
                top: 40%;
                padding: 15px 20px;
            }
            
            img {
                width: 75%;
                max-width: 800px;

                @include mq-m() {
                    width: 80%;
                    max-width: 450px;
                }
                @include mq-s() {
                    width: 80%;
                    max-width: 500px;
                }
            }
        }
    }


    &_nav {
        position: relative;
        z-index: 20;
        margin-top: -17.5%;

        @include mq-l() {
            margin-top: -14%;
        }
        @include mq-m() {
            margin-top: 30px;
        }
        @include mq-s() {
            margin-top: 30px;
        }

        &-inner {
            width: 100%;
            max-width: 1320px;
            margin: 0 auto;
            padding: 0 30px;

            @include mq-l() {
                padding: 0 20px;
            }
            @include mq-m() {
                padding: 0 10px;
            }
            @include mq-s() {
                padding: 0 8px;
            }
        }

        &-list {
            @include clearfix;

            li {
                float: left;
                width: 25%;
                padding: 0 10px;

                @include mq-m() {
                    width: 50%;
                    padding: 20px 10px 0;
                }
                @include mq-s() {
                    width: 50%;
                    padding: 20px 5px 0;
                }
            }
        }

        &-box {
            background: $C_WHITE;
            color: $C_BLACK;
            border-radius: 8px;
            box-shadow: 0 0 6.5px 0.4px rgba(0, 0, 0, 0.1);

            .target-1,
            .target-2,
            .target-3,
            .target-4 {
                display: block;
            }

            .topnav-img {
                position: relative;
                padding-bottom: 31px;

                @include mq-l() {
                    padding-bottom: 25px;
                }
                @include mq-m() {
                    padding-bottom: 25px;
                }
                @include mq-s() {
                    padding-bottom: 20px;
                }
                
                img {
                    border-radius: 6px 6px 0 0;
                    transition: 0.3s;
                }
            }
            .topnav-icon {
                position: absolute;
                width: 62px;
                border-radius: 0;
                margin: 0 auto;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 10;

                @include mq-l() {
                    width: 50px;
                }
                @include mq-m() {
                    width: 50px;
                }
                @include mq-s() {
                    width: 40px;
                }
            }
            .topnav-text {
                padding: 0 20px;

                @include mq-l() {
                    padding: 0 10px;
                }
                @include mq-m() {
                    padding: 0 15px;
                }
                @include mq-s() {
                    padding: 0 10px;
                }

                h3 {
                    a {
                        display: block;
                        width: 100%;
                        text-align: center;
                        font-size: 2rem;
                        line-height: 1.5em;
                        letter-spacing: 0.1em;
                        padding: 20px 0 10px 0.1em;
                        border-bottom: 1px solid #ddd;
                        margin-bottom: 10px;
                        transition: 0.3s;

                        @include mq-l() {
                            letter-spacing: 0.03em;
                            padding: 20px 0 10px 0.03em;
                        }
                        @include mq-m() {
                            letter-spacing: 0.03em;
                            padding: 20px 0 10px 0.03em;
                        }
                        @include mq-s() {
                            font-size: 1.65rem;
                            line-height: 1.5em;
                            letter-spacing: 0.03em;
                            padding: 20px 0 10px 0.03em;
                        }

                        &:before {
                            display: none;
                        }

                        &:hover {
                            opacity: 0.6;
                        }
                    }
                }

                ul {
                    padding: 0 0 20px;
                }

                li {
                    float: none;
                    width: 100%;
                    padding: 0 10px;
                }

                a {
                    display: block;
                    width: 100%;
                    color: $C_BLACK;
                    transition: 0.3s;
                    line-height: 1.3em;
                    padding: 8px 0 8px 20px;
                    position: relative;

                    @include mq-s() {
                        font-size: 1.3rem;
                        line-height: 1.3em;
                    }

                    &:hover,
                    &.hover {
                        text-decoration: underline;
                    }

                    &:before {
                        content: '';
                        display: block;
                        width: 12px;
                        height: 12px;
                        background: url("/images/common/icon-arrow_2.png") center center no-repeat;
                        background-size: 12px;
                        position: absolute;
                        left: 0;
                        top: calc(50% - 6px);
                    }
                }
            }
        }
    }


    &_flow {
        padding: 60px 0;

        &-inner {
            width: 100%;
            max-width: 1308px;
            padding: 0 42px;
            margin: 0 auto;

            @include mq-l() {
                padding: 0 30px;
            }
            @include mq-m() {
                padding: 0 18px;
            }
            @include mq-s() {
                padding: 0 8px;
            }
        }

        &-list {
            width: 100%;
            padding-bottom: 40px;
            @include clearfix;

            li {
                padding: 0 4px;
                float: left;
                width: 18%;
                width: calc(calc(100% - 64px) / 5);

                @include mq-l() {
                    width: 31.5%;
                    width: calc(calc(100% - 48px) / 3);

                    &:nth-child(-n+6) {
                        padding-bottom: 15px;
                    }
                }
                @include mq-m() {
                    width: 100%;
                    float: none;
                }
                @include mq-s() {
                    width: 100%;
                    float: none;
                }

                a {
                    display: block;
                    transition: 0.3s;

                    &:hover,
                    &.hover {
                        opacity: 0.6;
                    }
                }

                &.flow-arw {
                    width: 16px;
                    display: table;
                    table-layout: fixed;
                    vertical-align: middle;

                    @include mq-m() {
                        width: 100%;
                        
                        img {
                            transform: rotate(90deg);
                        }
                    }
                    @include mq-s() {
                        width: 100%;
                        
                        img {
                            transform: rotate(90deg);
                        }
                    }

                    .flow-arw_inner {
                        display: table-cell;
                        vertical-align: middle;

                        @include mq-m() {
                            display: block;
                            width: 8px;
                            margin: 0 auto;
                        }
                        @include mq-s() {
                            display: block;
                            width: 8px;
                            margin: 0 auto;
                        }
                    }
                }

                .flow-img {
                    position: relative;
                    border-radius: 6px;
                    box-shadow: 0 0 6.5px 0.4px rgba(0, 0, 0, 0.1);
                    @include clearfix;

                    img {
                        border-radius: 5px;

                        @include mq-m() {
                            border-radius: 5px 0 0 5px;
                            float: left;
                            width: 180px;
                            height: 70px;
                        }
                        @include mq-s() {
                            border-radius: 5px 0 0 5px;
                            float: left;
                            width: 144px;
                            height: 56px;
                        }
                    }
                }

                .flow-title {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    padding: 8px 10px;
                    text-align: center;
                    background: rgba(0, 0, 0, 0.56);
                    color: $C_WHITE;
                    border-radius: 0 0 5px 5px;
                    display: table;
                    table-layout: fixed;
                    vertical-align: middle;
                    width: 100%;

                    @include mq-m() {
                        position: unset;
                        float: right;
                        width: calc(100% - 180px);
                        line-height: 70px;
                        background: rgba(0, 0, 0, 0);
                        color: $C_BLACK;
                        padding: 0;
                    }
                    @include mq-s() {
                        position: unset;
                        float: right;
                        width: calc(100% - 144px);
                        line-height: 56px;
                        background: rgba(0, 0, 0, 0);
                        color: $C_BLACK;
                        padding: 0;
                    }
                    @media only screen and (max-width: 320px) {
                        font-size: 0.8em;
                    }

                    &-s {
                        font-size: 0.85em;
                        line-height: 1.25em;

                        @include mq-m() {
                            font-size: 1em;
                            line-height: 1.25em;
                            padding-top: 18px;
                        }
                        @include mq-s() {
                            font-size: 1em;
                            line-height: 1.25em;
                            padding-top: 10px;
                        }
                        @media only screen and (max-width: 320px) {
                            font-size: 0.8em;
                            padding-top: 14px;
                        }
                    }

                    p {
                        display: table-cell;
                        vertical-align: middle;
                    }
                }
            }
        }
    }


    &_course {
        background: url("/images/common/common-bg.png") repeat;
        padding: 40px 0;

        &-inner {
            width: 100%;
            max-width: 1320px;
            padding: 0 36px;
            margin: 0 auto;

            @include mq-l() {
                padding: 0 25px;
            }
            @include mq-m() {
                padding: 0 10px;
            }
            @include mq-s() {
                padding: 0 3px;
            }
        }

        &-list {
            display: flex;
            flex-wrap:wrap;
            justify-content: center;

            li {
                padding: 0 15px;
                width: 33.33333%;

                @include mq-m() {
                    width: 100%;

                    &:nth-child(2) {
                        padding: 20px 15px;
                    }
                }
                @include mq-s() {
                    width: 100%;

                    &:nth-child(2) {
                        padding: 15px;
                    }
                }
            }
        }

        &-box {
            border-radius: 5px;
            background: $C_WHITE;
            border: 1px solid $C_SUB1;

            .box-title {
                font-size: 2rem;
                line-height: 1;
                letter-spacing: 0.1em;
                padding: 8px 15px;
                background: $C_SUB1;
                color: $C_WHITE;
                text-align: center;

                @include mq-l() {
                    font-size: 1.8rem;
                    line-height: 1;
                }
                @include mq-m() {
                    font-size: 1.8rem;
                    line-height: 1;
                }
                @include mq-s() {
                    font-size: 1.8rem;
                    line-height: 1;
                }

                &::before {
                    content: '';
                    display: inline-block;
                    width: 25px;
                    height: 15px;
                    background: url("/images/common/icon-arrow_1.png") left center no-repeat;
                    background-size: 15px;
                }
            }
            .box-link {
                padding: 15px 20px;

                li {
                    padding: 4px 0;
                    width: 100%;
                }
                a {
                    display: block;
                    background: $C_WHITE;
                    color: $C_BLACK;
                    padding: 8px 10px 8px 28px;
                    border: 1px solid $C_SUB1;
                    border-radius: 100px;
                    line-height: 1.2em;
                    position: relative;
                    transition: 0.5s;

                    &::before {
                        content: '';
                        display: block;
                        width: 12px;
                        height: 12px;
                        background: url("/images/common/icon-arrow_3.png") center center no-repeat;
                        background-size: 12px;
                        position: absolute;
                        left: 10px;
                        top: calc(50% - 6px);
                        transition: 0.5s;
                    }

                    &:hover,
                    &.hover {
                        background: $C_SUB1;
                        color: $C_WHITE;

                        &::before {
                            background: url("/images/common/icon-arrow_1.png") center center no-repeat;
                            background-size: 12px;
                        }
                    }
                }
            }
            p {
                padding: 0 20px 20px;
            }
        }
    }

    &_topics {
        padding: 60px 0;

        @include mq-l() {
            padding: 50px 0;
        }
        @include mq-m() {
            padding: 40px 0;
        }
        @include mq-s() {
            padding: 30px 0;
        }

        &-inner {
            width: 100%;
            max-width: 1300px;
            padding: 0 50px;
            margin: 0 auto;

            
            @include mq-l() {
                padding: 0 36px;
            }
            @include mq-m() {
                padding: 0 36px;
            }
            @include mq-s() {
                padding: 0 15px;
            }

            .section-title_h2 {
                margin-bottom: 0;
            }
        }

        &-list {
            padding-bottom: 40px;

            li {
                border-top: 1px dashed $C_BLACK;
                padding: 20px 60px;
                @include clearfix;

                @include mq-l() {
                    padding: 20px 30px;
                }
                @include mq-m() {
                    padding: 20px 10px;
                }
                @include mq-s() {
                    padding: 20px 10px;
                }

                &:last-child {
                    border-bottom: 1px dashed $C_BLACK;
                }

                a {
                    display: block;
                    width: 100%;
                    @include clearfix;
                    transition: 0.5s;

                    &:hover {
                        color: $C_LINK !important;
                    }
                }
            }

            .topics-date {
                float: left;
                width: 12%;
                font-family: $EN_FONT_FAMILY;
                font-weight: bold;

                @include mq-m() {
                    float: none;
                    width: 100%;
                    padding-bottom: 5px;
                    font-size: 1.5rem;
                    line-height: 1.5em;
                }
                @include mq-s() {
                    float: none;
                    width: 100%;
                    padding-bottom: 5px;
                    font-size: 1.5rem;
                    line-height: 1.5em;
                }
            }
            .topics-detail {
                float: right;
                width: 88%;
                padding-left: 20px;

                @include mq-m() {
                    float: none;
                    width: 100%;
                    padding-left: 0;
                }
                @include mq-s() {
                    float: none;
                    width: 100%;
                    padding-left: 0;
                }

                &_title {
                    padding-bottom: 10px;
                    @include clearfix;

                    &-cat {
                        width: 80px;
                        text-align: center;
                        background: #7d7d7d;
                        color: $C_WHITE;
                        padding: 2px 5px;
                        border-radius: 5px;
                        float: left;
                    }
                    &-text {
                        padding: 2px 0 0 10px;
                        font-weight: bold;
                        float: right;
                        width: calc(100% - 80px);
                    }
                }

                &_body {
                    a {
                        display: inline-block;
                        color: $C_BLACK;
                        text-decoration: underline;

                        &:hover {
                            color: $C_LINK;
                            text-decoration: none;
                        }
                    }

                    span.link-text {
                        text-decoration: underline;

                        .fas {
                            margin-right: 2px;
                        }
                    }
                }
            }
        }
    }


    &_clinic {
        background: url("/images/common/common-bg.png") repeat;
        padding: 40px 0 50px;

        &-inner {
            width: 100%;
            max-width: 1296px;
            padding: 0 36px;
            margin: 0 auto;

            @include mq-l() {
                padding: 0 24px;
            }
            @include mq-m() {
                padding: 0 24px;
            }
            @include mq-s() {
                padding: 0 5px;
            }

            .section-title_top {
                @include mq-m() {
                    padding: 0 20px 30px;
                }
                @include mq-s() {
                    padding: 0 20px 20px;
                }
            }
            .section-title_text {
                text-align: center !important;
            }
        }

        &-list {
            @include clearfix;

            li {
                float: left;
                width: 33.333%;
                padding: 12px;

                @include mq-m() {
                    width: 50%;
                    padding: 10px;
                }
                @include mq-s() {
                    width: 100%;
                    padding: 10px;
                }
            }
        }

        &-box {
            background: $C_WHITE;
            border-radius: 5px;
            box-shadow: 0 0 6.5px 0.4px rgba(0, 0, 0, 0.1);

            .clinic-box_img {
                img {
                    border-radius: 6px 6px 0 0;
                }
            }
            .clinic-box_title {
                text-align: center;
                font-size: 1.6rem;
                line-height: 1.25em;
                padding: 20px 10px;

                @include mq-l() {
                    font-size: 1.45rem;
                    line-height: 1.25em;
                }
                @include mq-m() {
                    font-size: 1.45rem;
                    line-height: 1.25em;
                }
            }
            .clinic-box_link {
                @include clearfix;

                a {
                    display: block;
                    width: 50%;
                    text-align: center;
                    padding: 10px;

                    @include mq-l() {
                        padding: 10px 5px;
                        font-size: 1.3rem;
                    }
                    @include mq-m() {
                        padding: 10px 5px;
                        font-size: 1.3rem;
                    }

                    span {
                        &::before {
                        }
                    }
                }
                &-fl {
                    background: #999;
                    color: $C_WHITE;
                    float: left;
                    border-radius: 0 0 0 5px;
                    transition: 0.5s;

                    &:hover,
                    &.hover {
                        background: $C_BLACK;
                    }

                    span {
                        &::before {
                            content: '';
                            display: inline-block;
                            width: 20px;
                            height: 12px;
                            background: url("/images/common/icon-arrow_1.png") left center no-repeat;
                            background-size: 12px;
                        }
                    }
                }
                &-fr {
                    background: #f5f5f5;
                    color: #666;
                    float: right;
                    border-radius: 0 0 5px 0;
                    transition: 0.5s;

                    &:hover,
                    &.hover {
                        color: $C_WHITE;
                        background: $C_BLACK;
                    }

                    span {
                        &::before {
                            content: '';
                            display: inline-block;
                            width: 20px;
                            height: 12px;
                            background: url("/images/common/icon-arrow_2.png") left center no-repeat;
                            background-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

.close {
    display: table;
    margin: 0 auto;
    padding: 60px 20px 0;

    @include mq-s() {
        padding: 50px 15px 0;
    }

    &__inner {
        display: table-cell;
        vertical-align: middle;
        padding: 20px;
        border: 3px double $C_MAIN;

        &--title {
            text-align: center;
            font-weight: bold;
            font-size: 1.2em;
            margin-bottom: 10px;
            color: #6c241c;
        }
        &--text {
            line-height: 2;
            margin-bottom: 10px;
        }
    }
}