// ========================================

//     common
//         共通に使えるパーツ

//     INDEX
//         - wrapper

// ========================================

/* ========================================
    wrapper
======================================== */
.wrapper {
    position: relative;
    min-height: 100%;
    height: auto !important;
    height: 100%;
}


/* ========================================
    loading
======================================== */
.loadingWrap {
    width: 100vw;
    height: 100vh;
    background: $C_WHITE;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;

    img{
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -16px;
        margin-left: -16px;
        max-width: 32px;
    }
}


/* ========================================
    container
======================================== */
.main-container {
    background: #fbfbfb;
    padding-bottom: 185px;

    @include mq-l() {
        padding-bottom: 250px;
    }
    @include mq-m() {
        padding-bottom: 310px;
    }
    @include mq-s() {
        padding-bottom: 330px;
    }
}

.section-container {
    width: 100%;
    max-width: 1300px;
    padding: 40px 50px;
    margin: 0 auto;

    @include mq-l() {
        padding: 40px 30px;
    }
    @include mq-m() {
        padding: 20px 30px;
    }
    @include mq-s() {
        padding: 20px;
    }
}


/* ========================================
    contact
======================================== */
.section-contact {
    width: 100%;
    max-width: 1300px;
    padding: 40px 50px;
    margin: 0 auto;

    @include mq-l() {
        padding: 40px 30px;
    }
    @include mq-m() {
        padding: 40px 30px;
    }
    @include mq-s() {
        padding: 30px 20px;
    }

    &_inner {
        width: 100%;
        border-radius: 5px;
        padding: 35px;
        background: $C_WHITE;
        border: 1px solid $C_SUB1;
        display: table;
        table-layout: fixed;
        vertical-align: middle;

        @media only screen and (max-width: 1120px) {
            padding: 35px 20px;
        }
        @media only screen and (max-width: 1000px) {
            padding: 20px;
        }
        @include mq-l() {
            padding: 30px 20px;
        }
        @include mq-m() {
            padding: 24px 20px 30px;
            display: block;
        }
        @include mq-s() {
            padding: 20px 20px 25px;
            display: block;
        }

        &-text {
            text-align: center;
            width: 50%;
            display: table-cell;
            vertical-align: middle;

            @include mq-m() {
                display: block;
                width: 100%;
                padding-bottom: 15px;
            }
            @include mq-s() {
                display: block;
                width: 100%;
                padding-bottom: 15px;
            }

            .title {
                font-size: 2.4rem;
                line-height: 1.72em;
                letter-spacing: 0.1em;
                padding-left: 0.1em;
                padding-bottom: 5px;

                @media only screen and (max-width: 1200px) {
                    font-size: 2.2rem;
                    line-height: 1.72em;
                }
                @media only screen and (max-width: 1100px) {
                    font-size: 2rem;
                    line-height: 1.72em;
                }
                @include mq-l() {
                    font-size: 1.88rem;
                    line-height: 1.72em;
                    letter-spacing: 0.05em;
                    padding-left: 0.05em;
                }
                @include mq-m() {
                    font-size: 2rem;
                    line-height: 1.72em;
                }
                @include mq-s() {
                    font-size: 1.78rem;
                    line-height: 1.5em;
                    text-align: center;
                    letter-spacing: 0;
                    padding-left: 0;
                }
            }

            .text {
                padding-bottom: 4px;

                @include mq-l() {
                    font-size: 1.3rem;
                    line-height: 1.72em;
                }
                @media only screen and (min-width: 769px) and (max-width: 810px) {
                    letter-spacing: -0.05em;
                }
            }
        }

        &-link {
            text-align: center;
            width: 50%;
            display: table-cell;
            vertical-align: middle;

            @include mq-m() {
                display: block;
                width: 100%;
            }
            @include mq-s() {
                display: block;
                width: 100%;
            }

            dl {
                display: table;
                vertical-align: middle;
                margin: 15px auto 0;
                padding-left: 4px;

                @media only screen and (max-width: 360px) {
                    display: block;
                }
            }
            dt {
                padding: 0 8px;
                display: table-cell;
                vertical-align: middle;
                background: #f5f5f5;
                border-radius: 5px;

                @include mq-m() {
                    font-size: 1.2rem;
                    line-height: 1.72em;
                }
                @include mq-s() {
                    font-size: 1.2rem;
                    line-height: 1.72em;
                }
                @media only screen and (max-width: 360px) {
                    display: inline-block;
                }
            }
            dd {
                text-align: left;
                padding: 0 10px 0 6px;
                display: table-cell;
                vertical-align: middle;
                font-size: 1.6rem;
                line-height: 1em;
                font-family: $EN_FONT_FAMILY;

                @include mq-m() {
                    font-size: 1.45rem;
                    line-height: 1em;
                }
                @include mq-s() {
                    font-size: 1.2rem;
                    line-height: 1em;
                }
                @media only screen and (max-width: 360px) {
                    display: inline-block;
                }
            }

            .btn {
                display: inline-block;
                text-align: left;
                font-size: 1.8rem;
                line-height: 1em;
                padding: 15px 50px 15px 70px;
                border-radius: 5px;
                background: $C_SUB1;
                color: $C_WHITE;
                position: relative;
                transition: 0.5s;

                @include mq-l() {
                    font-size: 1.6rem;
                    line-height: 1em;
                    padding: 15px 30px 15px 50px;
                }
                @include mq-m() {
                    font-size: 1.6rem;
                    line-height: 1em;
                    padding: 15px 30px 15px 50px;
                }
                @include mq-s() {
                    display: block;
                    width: 100%;
                    font-size: 1.4rem;
                    line-height: 1em;
                    padding: 15px 20px 15px 40px;
                }

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    background: url('/images/common/icon-arrow_1.png') center center no-repeat;
                    background-size: 16px;
                    left: 15px;
                    top: calc(50% - 8px);
                }

                &:hover,
                &.hover {
                    background: $C_SUB2;
                    transition: 0.5s;
                }
            }
        }
    }
}

/* ========================================
    page top
======================================== */
#page-top {
    display: block;
    position: fixed;
    z-index: 9999;
    bottom: 155px;
    right: 0;
    width: 50px;
    height: 50px;
    text-align: center;
    opacity: 0.7;

    &:hover,
    &.hover {
        opacity: 0.95;
    }

    @include mq-m() {
        bottom: 12px;
    }
    @include mq-s() {
        bottom: 12px;
    }
}