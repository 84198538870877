// ========================================

//     about
//         /about/のみで使うスタイル

//     INDEX
//         - main

// ========================================

/* ========================================
    オプション検査
======================================== */
.cat-list {
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding: 40px;
    position: relative;
    margin: 12px 0 50px;
    @include clearfix;

    @include mq-l() {
        padding: 30px 20px;
    }
    @include mq-m() {
        padding: 30px 20px;
    }
    @include mq-s() {
        padding: 20px;
    }

    &_title {
        text-align: center;
        position: absolute;
        top: -12px;
        left: 0;
        right: 0;
        margin: 0 auto;

        &-inner {
            background: #fbfbfb;
            padding: 0 10px;
            display: inline-block;
            font-size: 1.8rem;
            line-height: 1.25em;
            font-weight: bold;
        }
    }

    &_list {
        width: 25%;
        float: left;
        padding: 0 5px;

        @include mq-m() {
            width: 50%;

            &.mb-cf {
                clear: both;
            }
        }
        @include mq-s() {
            width: 50%;

            &.mb-cf {
                clear: both;
            }
        }

        a {
            display: block;
            width: 100%;
            padding: 10px 5px 10px 20px;
            color: $C_BLACK;
            position: relative;
            line-height: 1.38em;

            @include mq-m() {
                padding: 8px 5px 8px 20px;
            }
            @include mq-s() {
                padding: 8px 5px 8px 20px;
                font-size: 1.2rem;
                line-height: 1.38em;
            }


            &:hover {
                text-decoration: underline;
            }

            &:before {
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                background: url("/images/common/icon-arrow_2.png") center center no-repeat;
                background-size: 12px;
                position: absolute;
                left: 0;
                top: calc(50% - 6px);
            }
        }
    }
}


.option-container {
    padding-bottom: 30px;

    &_box {
        border-radius: 5px;
        border: 1px solid #ccc;
        margin-bottom: 20px;

        .option-title {
            background: #f6f6f6;
            border-radius: 5px 5px 0 0;
            border-bottom: 1px solid #ccc;
            display: table;
            table-layout: fixed;
            vertical-align: middle;
            width: 100%;
            padding: 10px 40px;

            @include mq-l() {
                padding: 10px 30px;
            }
            @include mq-m() {
                padding: 10px 20px;
            }
            @include mq-s() {
                padding: 10px 20px;
            }

            &_text {
                display: table-cell;
                vertical-align: middle;
                font-size: 2.25rem;
                line-height: 1.3em;
                font-weight: bold;
                width: 62%;

                @include mq-l() {
                    font-size: 1.8rem;
                    line-height: 1.3em;
                }
                @include mq-m() {
                    font-size: 1.65rem;
                    line-height: 1.3em;
                    display: block;
                    width: 100%;
                }
                @include mq-s() {
                    font-size: 1.5rem;
                    line-height: 1.3em;
                    display: block;
                    width: 100%;
                }

                .sub {
                    display: inline-block;
                    margin-left: 10px;
                    color: $C_WHITE;
                    border: 1px solid $C_WHITE;
                    font-weight: normal;
                    padding: 4px 15px;
                    font-size: 1.6rem;
                    line-height: 2.25rem;

                    @include mq-l() {
                        padding: 0px 8px;
                        font-size: 1.4rem;
                        line-height: 2.25rem;
                    }
                    @include mq-m() {
                        padding: 0px 8px;
                        font-size: 1.4rem;
                        line-height: 2.25rem;
                    }
                    @include mq-s() {
                        padding: 0px 8px;
                        font-size: 1.3rem;
                        line-height: 2.25rem;
                    }

                    &-2 {
                        color: $C_MAIN;
                        border: 1px solid $C_MAIN;
                    }
                }
            }

            &_cat {
                display: table-cell;
                vertical-align: middle;
                text-align: right;

                @include mq-m() {
                    display: block;
                    width: 100%;
                    text-align: left;
                }
                @include mq-s() {
                    display: block;
                    width: 100%;
                    text-align: left;
                }

                span {
                    display: inline-block;
                    background: $C_GRAY;
                    border: 2px solid $C_GRAY;
                    text-align: center;
                    width: 130px;
                    padding: 3px;
                    border-radius: 100px;
                    margin-left: 5px;

                    @include mq-l() {
                        font-size: 1.25rem;
                        line-height: 1.72em;
                        width: 100px;
                    }
                    @include mq-m() {
                        font-size: 1.25rem;
                        line-height: 1em;
                        width: 30%;
                        margin: 8px 5px 0 0;
                    }
                    @include mq-s() {
                        font-size: 1.25rem;
                        line-height: 1em;
                        width: 30%;
                        margin: 8px 5px 0 0;
                    }

                    &.cat-b {
                        background: $C_BLACK;
                        color: $C_WHITE;
                        border: 2px solid $C_BLACK;
                    }
                    &.cat-r {
                        background: $C_WHITE;
                        color: $C_SUB1;
                        border: 2px solid $C_SUB1;
                    }
                }
            }
        }

        .option-inner {
            @include clearfix;
            padding: 20px 40px;

            @include mq-l() {
                padding: 20px 30px;
            }
            @include mq-m() {
                padding: 20px;
            }
            @include mq-s() {
                padding: 20px;
            }

            &_text {
                float: left;
                width: 62%;

                @include mq-m() {
                    float: none;
                    width: 100%;
                    padding-bottom: 20px;
                }
                @include mq-s() {
                    float: none;
                    width: 100%;
                    padding-bottom: 20px;
                }
            }
            &_price {
                float: right;
                width: 38%;
                @include clearfix;

                @include mq-m() {
                    float: none;
                    width: 100%;
                }
                @include mq-s() {
                    float: none;
                    width: 100%;
                }

                .price-box_sub {
                    float: right;

                    p {
                        font-family: $EN_FONT_FAMILY;
                        font-size: 3.8rem;
                        line-height: 1em;
                        text-align: right;

                        @include mq-l() {
                            font-size: 3.4rem;
                            line-height: 1em;

                            &.mb-txt {
                                letter-spacing: -0.01em;
                            }
                        }
                        @include mq-m() {
                            font-size: 3.4rem;
                            line-height: 1em;
                            letter-spacing: 0;
                        }
                        @include mq-s() {
                            font-size: 3.2rem;
                            line-height: 1em;
                            letter-spacing: 0;
                        }

                        &.set-title {
                            font-size: 1rem;
                            line-height: 1em;
                            text-align: center;
                            border: 1px solid $C_BLACK;
                            padding: 4px;
                            margin-bottom: 4px;
                        }
                    }
                    span {
                        display: block;
                        font-size: 1.3rem;
                        line-height: 1.1em;

                        @include mq-l() {
                            letter-spacing: 0.03em;
                        }

                        &.subtext {
                            display: inline-block;
                            font-size: 1.8rem;
                            line-height: 1em;
                            font-weight: bold;
                            margin-left: 5px;

                            @include mq-l() {
                                font-size: 1.6rem;
                                line-height: 1em;
                            }
                            @include mq-m() {
                                font-size: 1.6rem;
                                line-height: 1em;
                            }
                            @include mq-s() {
                                font-size: 1.5rem;
                                line-height: 1em;
                            }
                        }
                    }
                }
                .price-box_main {
                    float: right;
                    padding-left: 20px;

                    @include mq-l() {
                        padding-left: 15px;
                    }

                    p {
                        font-family: $EN_FONT_FAMILY;
                        font-size: 2.9rem;
                        line-height: 1em;
                        text-align: right;

                        @include mq-l() {
                            font-size: 2.2rem;
                            line-height: 1em;
                        }
                        @include mq-m() {
                            font-size: 2.2rem;
                            line-height: 1em;
                        }
                        @include mq-s() {
                            font-size: 2.2rem;
                            line-height: 1em;
                        }
                    }
                    span {
                        display: block;
                        font-size: 1.2rem;
                        line-height: 1.1em;
                    }
                }
                .price-box_btn {
                    clear: both;
                    padding-top: 20px;

                    .btn-100 {
                        margin: 0 0 0 auto;

                        @include mq-s() {
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }

    &_special {
        .option-title {
            background: $C_SUB2;
            color: $C_WHITE;
        }
    }
}

.option-item {
    background: $C_WHITE;
    width: 80vw;
    max-width: 1200px;
    height: 90vh;
    max-height: 800px !important;
    padding: 30px 50px;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    margin: 0 auto;

    &_title {
        text-align: center;
        width: 90%;
        margin: 0 auto 15px;
        max-width: 700px;
    }

    &_text {
        padding: 0 10px;
    }
}




/* ========================================
    健診の流れ
======================================== */
.flow-container {
    padding-bottom: 30px;
    @include clearfix;

    &_title {
        float: left;
        width: 140px;

        @include mq-l() {
            width: 120px;
        }
        @include mq-m() {
            width: 100px;
        }
        @include mq-s() {
            width: 100%;
            float: none;
        }
    }
    &_detail {
        float: right;
        width: calc(100% - 140px);
        padding-left: 60px;

        @include mq-l() {
            width: calc(100% - 120px);
            padding-left: 30px;
        }
        @include mq-m() {
            width: calc(100% - 100px);
            padding-left: 20px;
        }
        @include mq-s() {
            width: 100%;
            float: none;
            padding-left: 0;
        }

        .sub-title {
            padding-top: 80px;
            margin-top: -80px;
        }

        &-inner {
            padding: 0 25px 40px;
            @include clearfix;

            @include mq-l() {
                padding: 0 20px 40px;
            }
            @include mq-m() {
                padding: 0 15px 40px;
            }
            @include mq-s() {
                padding: 0 10px 30px;
            }

            .fl {
                width: 74%;
                padding-right: 40px;

                @include mq-l() {
                    padding-right: 20px;
                }
                @include mq-m() {
                    float: none !important;
                    width: 100%;
                    padding-right: 0;
                }
                @include mq-s() {
                    float: none !important;
                    width: 100%;
                    padding-right: 0;
                }
            }
            .fr {
                width: 26%;
                text-align: center;

                @include mq-m() {
                    float: none !important;
                    width: 100%;
                    padding-top: 20px;
                }
                @include mq-s() {
                    float: none !important;
                    width: 100%;
                    padding-top: 20px;
                }

                img {
                    border-radius: 5px;
                }
            }
        }
    }

    .flow-title {
        background: $C_SUB1;
        background: linear-gradient(130deg, $C_SUB2 0%, $C_SUB2 40%, $C_SUB1 40%, $C_SUB1 100%);
        color: $C_WHITE;
        width: 140px;
        height: 140px;
        display: table;
        table-layout: fixed;
        text-align: center;
        vertical-align: middle;
        border-radius: 5px;

        @include mq-l() {
            width: 120px;
            height: 120px;
        }
        @include mq-m() {
            width: 100px;
            height: 100px;
        }
        @include mq-s() {
            width: 100%;
            height: 50px;
            margin-bottom: 20px;
        }

        p {
            display: table-cell;
            vertical-align: middle;
            font-size: 2rem;
            line-height: 1em;
        }
    }

    .flow_link-list {
        @include clearfix;
        position: relative;
        z-index: 99;

        li {
            width: 50%;
            float: left;

            @include mq-s() {
                width: 100%;
                float: none;
            }

            &:nth-child(2n+1) {
                padding-right: 6px;

                @include mq-s() {
                    padding-right: 0;
                }
            }
            &:nth-child(2n) {
                padding-left: 6px;

                @include mq-s() {
                    padding-top: 10px;
                    padding-left: 0;
                }
            }

            .btn-100 {
                margin: 0 auto 0 0;

                @include mq-m() {
                    max-width: 1000px;
                }
                @include mq-s() {
                    max-width: 1000px;
                }
            }
        }

        &.w100 {
            li {
                width: 100%;
                max-width: 1000px;
                padding-right: 0 !important;
                padding-left: 0 !important;
            }
        }
        &.w74 {
            width: 74%;
            padding-right: 40px;

            @include mq-l() {
                padding-right: 20px;
            }
            @include mq-m() {
                width: 100%;
                padding-right: 0;
            }
            @include mq-s() {
                width: 100%;
                padding-right: 0;
            }
        }
    }
}


/* ========================================
    検診コース
======================================== */
.sub-title {
    &#course-1,
    &#course-2,
    &#course-3,
    &#course-4 {
        padding-top: 80px;
        margin-top: -80px;
    }
}

.course-list {
    @include clearfix;
    padding-bottom: 40px;
    position: relative;
    z-index: 99;

    li {
        float: left;
        width: 50%;

        @include mq-m() {
            width: 100%;
        }
        @include mq-s() {
            width: 100%;
        }

        &:nth-child(2n+1) {
            padding: 10px 15px 30px 5px;

            @include mq-m() {
                padding: 10px 15px;
            }
            @include mq-s() {
                padding: 10px;
            }
        }
        &:nth-child(2n) {
            padding: 10px 5px 30px 15px;

            @include mq-m() {
                padding: 10px 15px;
            }
            @include mq-s() {
                padding: 10px;
            }
        }
    }
}

.course-container {
    border-radius: 6px;
    border: 1px solid #ccc;
    display: table;
    vertical-align: top;
    table-layout: fixed;
    width: 100%;

    @include mq-s() {
        display: block;
    }

    &_img {
        display: table-cell;
        vertical-align: middle;
        width: 40%;
        height: auto;

        @include mq-s() {
            display: block;
            width: 100%;
            height: 150px;
        }

        &.img_1 {
            background: url("/images/about/course_img-1.jpg") no-repeat;
            justify-content: center;
            align-items: center;
            background-size: cover;
            background-position: center;
        }
        &.img_2 {
            background: url("/images/about/course_img-2.jpg") no-repeat;
            justify-content: center;
            align-items: center;
            background-size: cover;
            background-position: center;
        }
        &.img_3 {
            background: url("/images/about/course_img-3.jpg") no-repeat;
            justify-content: center;
            align-items: center;
            background-size: cover;
            background-position: center;
        }
        &.img_4 {
            background: url("/images/about/course_img-4.jpg") no-repeat;
            justify-content: center;
            align-items: center;
            background-size: cover;
            background-position: center;
        }
        &.img_5 {
            background: url("/images/about/course_img-5.jpg") no-repeat;
            justify-content: center;
            align-items: center;
            background-size: cover;
            background-position: center;
        }
        &.img_6 {
            background: url("/images/about/course_img-6.jpg") no-repeat;
            justify-content: center;
            align-items: center;
            background-size: cover;
            background-position: center;
        }
        &.img_7 {
            background: url("/images/about/course_img-7.jpg") no-repeat;
            justify-content: center;
            align-items: center;
            background-size: cover;
            background-position: center;
        }
        &.img_8 {
            background: url("/images/about/course_img-8.jpg") no-repeat;
            justify-content: center;
            align-items: center;
            background-size: cover;
            background-position: center;
        }
        &.img_9 {
            background: url("/images/about/course_img-9.jpg") no-repeat;
            justify-content: center;
            align-items: center;
            background-size: cover;
            background-position: center;
        }
    }
    &_detail {
        display: table-cell;
        vertical-align: top;
        width: 60%;
        padding: 15px;

        @include mq-s() {
            display: block;
            width: 100%;
        }

        .accent-title {
            @include mq-l() {
                font-size: 1.65rem;
                line-height: 1.5em;
            }
            @include mq-m() {
                font-size: 1.65rem;
                line-height: 1.5em;
            }
            @include mq-s() {
                font-size: 1.6rem;
                line-height: 1.5em;
            }
        }

        a {
            display: block;
            border-radius: 5px;
            width: 100%;
            padding: 4px 10px;
            background: #909090;
            color: $C_WHITE;
            border: 1px solid #909090;
            text-align: center;
            margin: 15px 0 5px auto;
            transition: 0.5s;

            span {
                &::before {
                    content: '';
                    display: inline-block;
                    width: 20px;
                    height: 12px;
                    background: url("/images/common/icon-arrow_1.png") left center no-repeat;
                    background-size: 12px;
                }
            }

            &:hover,
            &.hover {
                background: $C_WHITE;
                color: $C_BLACK;

                span {
                    &::before {
                        background: url("/images/common/icon-arrow_2.png") left center no-repeat;
                        background-size: 12px;
                    }
                }
            }
        }
    }
}

.course_box {
    margin: 0 5px;
    position: relative;
    z-index: 99;
}


.course-menu {
    border-radius: 6px;
    border: 1px solid #ccc;
    display: table;
    vertical-align: middle;
    table-layout: fixed;
    width: calc(100% - 10px);
    margin: 0 auto;

    @include mq-s() {
        display: block;
        margin: 20px auto 0;
    }

    &_title {
        display: table-cell;
        vertical-align: middle;
        width: 16%;
        padding: 15px;
        border-right: 1px solid #ccc;
        text-align: center;
        font-size: 1.6rem;
        line-height: 1em;
        font-weight: bold;

        @include mq-m() {
            width: 25%;
            font-size: 1.5rem;
            line-height: 1em;
        }
        @include mq-s() {
            display: block;
            width: 100%;
            border-right: 0;
            border-bottom: 1px solid #ccc;
        }
    }
    &_detail {
        display: table-cell;
        vertical-align: middle;
        width: 84%;
        padding: 15px 20px;

        @include mq-m() {
            width: 75%;
        }
        @include mq-s() {
            display: block;
            width: 100%;
            padding: 15px;
        }
    }
}


.btn-course {
    display: block;
    border-radius: 5px;
    width: 100%;
    max-width: 320px;
    padding: 4px 10px;
    background: #909090;
    color: $C_WHITE;
    border: 1px solid #909090;
    text-align: center;
    margin: 10px 0 0 auto;
    transition: 0.5s;

    span {
        &::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 12px;
            background: url("/images/common/icon-arrow_1.png") left center no-repeat;
            background-size: 12px;
        }
    }

    &:hover,
    &.hover {
        background: $C_WHITE;
        color: $C_BLACK;

        span {
            &::before {
                background: url("/images/common/icon-arrow_2.png") left center no-repeat;
                background-size: 12px;
            }
        }
    }
}



/* ========================================
    各コース
======================================== */
.course-info {
    &_price {
        width: calc(100% - 10px);
        margin: 0 auto 40px;
        border-radius: 6px;
        border: 1px solid #ccc;
        display: table;
        vertical-align: middle;
        table-layout: fixed;

        @include mq-s() {
            display: block;
        }

        dt {
            display: table-cell;
            vertical-align: middle;
            width: 20%;
            padding: 15px;
            border-right: 1px solid #ccc;
            text-align: center;
            font-size: 2rem;
            font-weight: bold;
            line-height: 1em;

            @include mq-s() {
                display: block;
                width: 100%;
                border-right: 0;
                border-bottom: 1px solid #ccc;
                text-align: left;
                font-size: 1.65rem;
            }
        }
        dd {
            display: table-cell;
            vertical-align: middle;
            width: 80%;
            padding: 15px 20px;
            @include clearfix;

            @include mq-s() {
                display: block;
                width: 100%;
                text-align: left;
            }

            .price-title {
                float: left;
                font-size: 1.6rem;
                line-height: 3rem;
                letter-spacing: 0.05em;
                padding-right: 20px;

                @include mq-s() {
                    float: none;
                    padding-right: 0;
                    padding-bottom: 2px;
                    font-size: 1.4rem;
                    line-height: 1.5em;
                }

                .fas {
                    margin-right: 4px;
                }
            }

            .price-text {
                float: left;
                font-weight: bold;
                font-family: $EN_FONT_FAMILY;
                font-size: 3rem;
                line-height: 3rem;
                letter-spacing: 0.05em;

                @include mq-s() {
                    float: none;
                    padding-right: 0;
                    line-height: 1.5em;
                }
    
                span {
                    display: inline-block;
                    font-size: 1.5rem;
                    line-height: 3rem;
                    padding-left: 5px;
                }
            }
        }
    }

    &_list {
        width: calc(100% - 10px);
        margin: 0 auto 20px;

        @include mq-m() {
            font-size: 1.3rem;
            line-height: 1.5em;
        }
        @include mq-s() {
            font-size: 1.2rem;
            line-height: 1.5em;
        }

        tr {
            &:nth-child(2n+1) {
                background: #edecec;
            }
        }

        th,
        td {
            border: 1px solid $C_BLACK;
            padding: 10px;

            @include mq-s() {
                padding: 10px 5px;
            }
        }

        th {
            text-align: center;
            font-weight: bold;
            background: $C_SUB1;
            color: $C_WHITE;

            &.list_w-short {
                width: 22%;
                border-right: 1px solid $C_WHITE;
            }
            &.list_w-long1 {
                width: 44%;
                border-right: 1px solid $C_WHITE;
            }
            &.list_w-long2 {
                width: 44%;
            }
        }

        td {
            &:first-child {
                text-align: center;
            }
        }
    }

    &_text {
        padding: 0 5px;
    }
}


/* ========================================
    健診前のご注意
======================================== */
.note-list {
    padding: 0 5px 50px;

    li {
        list-style-type: disc;
        padding: 4px 0;
        margin: 0 20px 0 35px;

        @include mq-l() {
            margin: 0 13px 0 28px;
        }
        @include mq-m() {
            margin: 0 10px 0 25px;
        }
        @include mq-s() {
            margin: 0 5px 0 18px;
        }
    }

    &_sub {
        li {
            list-style-type: circle;
            padding:  0;
            margin: 0 0 0 14px;
        }
    }

    &_att {
        li {
            list-style-type: none;
            padding:  0;
            margin: 0;

            &::before {
                content: '※ ';
            }
        }
    }

    &_table {
        width: calc(100% - 40px);
        margin: 0 auto 50px;

        @include mq-m() {
            width: calc(100% - 30px);
        }
        @include mq-s() {
            width: calc(100% - 20px);
        }


        tr {
            &:nth-child(2n+1) {
                background: #edecec;
            }
        }

        th {
            text-align: center;
            font-weight: bold;
            background: $C_SUB1;
            color: $C_WHITE;
            padding: 10px;
            border: 1px solid $C_BLACK;

            &.list_w-1 {
                width: 10%;
                border-right: 1px solid $C_WHITE;

                @include mq-m() {
                    width: 20%;
                }
                @include mq-s() {
                    width: 20%;
                }
            }
            &.list_w-2 {
                width: 10%;
                border-right: 1px solid $C_WHITE;

                @include mq-m() {
                    width: 20%;
                }
                @include mq-s() {
                    width: 20%;
                }
            }
            &.list_w-3 {
                width: 80%;

                @include mq-m() {
                    width: 60%;
                }
                @include mq-s() {
                    width: 60%;
                }
            }
        }

        td {
            border: 1px solid $C_BLACK;
            padding: 5px 10px 6px;

            &:nth-child(1) {
                text-align: center;
            }
            &:nth-child(2) {
                text-align: center;
                font-size: 1.2rem;
                line-height: 1.2em;
            }
            &:nth-child(3) {
                @include mq-s() {
                    font-size: 1.3rem;
                    line-height: 1.6em;
                }
            }

            img {
                width: 30px;
                display: block;
                margin: 0 auto;
                padding: 6px 0 4px;
            }
        }
    }

    &_text {
        padding: 0 25px;

        @include mq-l() {
            padding: 0 20px;
        }
        @include mq-m() {
            padding: 0 15px;
        }
        @include mq-s() {
            padding: 0 10px;
        }
    }
}


/* ========================================
    健診前のご注意
======================================== */
.day-flow {

    li {
        @include clearfix;
        padding: 25px 0;
    }

    &_full {
        width: 100%;
        
        dd {
            padding: 0 25px;

            li {
                padding: 0;
            }
        }
    }

    &_text {
        float: left;
        width: 70%;
        padding-right: 20px;

        @include mq-l() {
            width: 65%;
        }
        @include mq-m() {
            float: none;
            width: 100%;
            padding: 0 0 20px 0;
        }
        @include mq-s() {
            float: none;
            width: 100%;
            padding: 0 0 20px 0;
        }

        dd {
            padding: 0 25px;

            @include mq-l() {
                padding: 0 10px 0 18px;
            }
            @include mq-m() {
                padding: 0 10px;
            }
            @include mq-s() {
                padding: 0 10px;
            }

            li {
                padding: 0;
            }
        }
    }
    &_img {
        float: right;
        width: 30%;

        @include mq-l() {
            width: 35%;
            padding-top: 10px;
        }

        @include mq-m() {
            float: none;
            width: 100%;
            padding: 0 10px;
        }
        @include mq-s() {
            float: none;
            width: 100%;
            padding: 0 10px;
        }
    }
}