// ========================================

//     other
//         そのほかで使うスタイル

//     INDEX
//         - main

// ========================================

/* ========================================
    sitemap
======================================== */
.sitemap-dl {
    dt {
        padding-bottom: 20px;
        font-size: 1.65rem;
        line-height: 1.5em;

        a {
            display: block;
            background: #f6f6f6;
            border-radius: 5px;
            width: 100%;
            padding: 10px 45px;
            position: relative;
            color: $C_BLACK;
            transition: 0.5s;
            //cursor: pointer;

            &:before {
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                background: url("/images/common/icon-arrow_2.png") center center no-repeat;
                background-size: 12px;
                position: absolute;
                left: 20px;
                top: calc(50% - 6px);
            }

            &:hover,
            &.hover {
                background: $C_BLACK;
                color: $C_WHITE;
            }
        }

        p {
            background: #f6f6f6;
            border-radius: 5px;
            width: 100%;
            padding: 10px 22px;
        }
    }

    dd {
        padding: 0 10px;
    }

    &_title {
        font-size: 1.5rem;
        line-height: 1.5em;
        font-weight: bold;
        margin-bottom: 10px;
        border-bottom: 3px double #cecdcd;

        a {
            padding: 5px 2px 6px 33px;
            color: $C_BLACK;
            transition: 0.5s;
            display: block;
            position: relative;

            &:before {
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                background: url("/images/common/icon-arrow_2.png") center center no-repeat;
                background-size: 12px;
                position: absolute;
                left: 10px;
                top: calc(50% - 6px);
            }

            &:hover,
            &.hover {
                color: $C_LINK;
            }
        }
    }
}

.sitemap-link {
    padding-bottom: 30px;
    @include clearfix;

    a {
        color: $C_BLACK;
        display: block;
        position: relative;
        padding: 4px 10px 4px 30px;

        &:before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: url("/images/common/icon-arrow_2.png") center center no-repeat;
            background-size: 12px;
            position: absolute;
            left: 10px;
            top: calc(50% - 6px);
        }

        &:hover,
        &.hover {
            text-decoration: underline;
        }
    }

    li {
        float: left;
        width: 33.33333%;

        &:nth-child(3n+1) {
            clear: both;

            @include mq-m() {
                clear: unset;
            }
            @include mq-s() {
                clear: unset;
            }
        }

        @include mq-m() {
            width: 50%;

            &:nth-child(2n+1) {
                clear: both;
            }
        }
        @include mq-s() {
            width: 100%;
        }
    }
}


/* ========================================
    policy
======================================== */
.policy-dl {
    dt {
        padding-bottom: 20px;
        font-size: 1.65rem;
        line-height: 1.5em;
        background: #f6f6f6;
        border-radius: 5px;
        width: 100%;
        padding: 10px 20px;
        color: $C_BLACK;
    }

    dd {
        padding: 20px 10px 30px;

        .list_1 {
            padding-left: 25px;

            li {
                list-style-type: decimal-leading-zero;
            }
        }
        .list_2 {
            padding-left: 25px;
            
            li {
                list-style-type: disc;
            }
        }
    }
}