// ========================================
  
//     list
//         リストのスタイル

//     INDEX
//         - text list
//         - thumb list
//         - circle list

// ========================================

// ========================================
//  text list
//      テキストのみリスト
// ========================================
.list {
    $this: &;
    display: block;

    &__item {
        word-break: break-word;
        font-size: 16px;
    }

    // ========================================
    //  thumb list
    //      サムネありリスト
    // ========================================
    &--thumb {
        @extend #{$this};

        #{$this}__item {
            font-size: 14px;
        }
    }

    // ========================================
    //  circle list
    //      丸いサムネありリスト
    // ========================================
    &--circle {
        @extend #{$this};

        #{$this}__item {
            font-size: 12px;
        }
    }
}


.list-circle {
    margin-left: 12px;
    
    li {
        list-style-type: disc;
    }
}