// ========================================

//     footer
//         フッターのパーツ

//     INDEX
//         - common

// ========================================

/* ========================================
    common
======================================== */
.footer {
    background: #edecec;
    position: absolute;
    bottom: 0;
    width: 100%;

    &_inner {
        width: 100%;
        max-width: 1300px;
        padding: 25px 50px;
        margin: 0 auto;
        @include clearfix;

        @include mq-l() {
            padding: 25px 30px;
        }
        @include mq-m() {
            padding: 25px 30px;
        }
        @include mq-s() {
            padding: 20px;
        }

        &-logo {
            float: left;
            width: 55%;

            @include mq-l() {
                width: 44%;
            }
            @include mq-m() {
                float: none;
                width: 100%;
                text-align: center;
                padding-bottom: 10px;
            }
            @include mq-s() {
                float: none;
                width: 100%;
                text-align: center;
                padding-bottom: 10px;
            }

            a {
                color: $C_BLACK;
                display: block;
                @include clearfix;
            }

            .fl {
                @include mq-l() {
                    float: none !important;
                }
                @include mq-m() {
                    float: none !important;
                }
                @include mq-s() {
                    float: none !important;
                }
                img {
                    height: 50px;
                    margin: 10px 15px 0 0;
                    vertical-align: middle;
    
                    @media only screen and (max-width: 1040px) {
                        height: 40px;
                    }
                    @include mq-l() {
                        margin: 0 0 10px;
                        height: 40px;
                    }
                    @include mq-m() {
                        margin: 0 0 10px;
                        height: 40px;
                    }
                    @include mq-s() {
                        margin: 0 0 10px;
                        height: 40px;
                    }
                }
                .small {
                    line-height: 1.2;
                }
            }
        }

        &-link {
            float: right;
            width: 45%;
            text-align: right;

            @include mq-l() {
                width: 56%;
            }
            @include mq-m() {
                float: none;
                width: 100%;
                text-align: center;
            }
            @include mq-s() {
                float: none;
                width: 100%;
                text-align: center;
            }

            ul {
                @include clearfix;
                font-size: 0;
            }

            li {
                display: inline-block;
                position: relative;

                @include mq-s() {
                    display: block;
                    float: left;
                    width: 50%;
                    text-align: center;

                    &:nth-child(2) {
                        &:after {
                            display: none;
                        }
                    }
                }

                &:after {
                    content: '';
                    display: block;
                    width: 1px;
                    height: 20px;
                    background: $C_BLACK;
                    position: absolute;
                    right: 0;
                    top: calc( 50% - 8px);
                }

                a {
                    color: $C_BLACK;
                    display: inline-block;
                    height: 45px;
                    line-height: 45px;
                    padding: 0 15px;
                    font-size: 1.35rem;

                    @media only screen and (max-width: 1080px) {
                        padding: 0 10px;
                        ont-size: 1.3rem;
                    }
                    @media only screen and (max-width: 1040px) {
                        padding: 0 8px;
                        font-size: 1.2rem;
                    }
                    @include mq-l() {
                        padding: 0 8px;
                        font-size: 1.2rem;
                    }
                    @include mq-s() {
                        height: 30px;
                        line-height: 30px;
                    }
                }

                &:first-child {
                    a {
                        padding: 0 15px 0 0;

                        @media only screen and (max-width: 1060px) {
                            padding: 0 10px 0 0;
                        }
                        @include mq-s() {
                            padding: 0 15px;
                        }
                    }
                }
                &:last-child {
                    &:after {
                        display: none;
                    }
                    a {
                        padding: 0 0 0 15px;

                        @media only screen and (max-width: 1060px) {
                            padding: 0 0 0 10px;
                        }
                        @include mq-s() {
                            padding: 0 15px;
                        }
                    }
                }
            }
        }
    }

    &_copy {
        background: $C_SUB1;
        color: $C_WHITE;
        text-align: center;
        padding: 15px 20px;
        font-size: 1.2rem;
    }
}
