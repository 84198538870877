// ========================================

//     form
//         form系のみで使うスタイル

//     INDEX
//         - main

// ========================================

/* ========================================
    contact
======================================== */
.contact-container {

    .contact-table {
        width: 100%;
        margin-bottom: 30px;

        tr {
            &:nth-child(2n+1) {
                background: #edecec;
            }

            @include mq-m() {
                clear: both;
            }
            @include mq-s() {
                clear: both;
            }
        }

        th,
        td {
            padding: 18px 30px;
            vertical-align: top;
        }

        th {
            width: 26%;
            border-right: 1px solid $C_BLACK;

            @include mq-m() {
                width: 100%;
                float: left;
                padding: 15px 20px 4px;
                border-right: 0;
                font-weight: bold;
                font-size: 1.6rem;
                line-height: 1.5em;
            }
            @include mq-s() {
                width: 100%;
                float: left;
                padding: 15px 20px 4px;
                border-right: 0;
                font-weight: bold;
                font-size: 1.6rem;
                line-height: 1.5em;
            }
        }

        td {
            @include mq-m() {
                width: 100%;
                float: left;
                padding: 4px 20px 20px;
            }
            @include mq-s() {
                width: 100%;
                float: left;
                padding: 4px 20px 20px;
            }

            &.title {
                padding: 18px 5px 18px 30px;

                @include mq-m() {
                    padding: 4px 0 20px 20px;
                    width: 18%;

                    &.mb-p {
                        padding: 4px 0 10px 20px;
                    }
                }
                @include mq-s() {
                    padding: 4px 0 20px 20px;
                    width: 18%;

                    &.mb-p {
                        padding: 4px 0 10px 20px;
                    }
                }
            }
            &.cont {
                padding: 18px 30px 18px 5px;

                @include mq-m() {
                    padding: 4px 20px 20px 0;
                    width: 82%;

                    &.mb-p {
                        padding: 4px 20px 10px 0;
                    }
                }
                @include mq-s() {
                    padding: 4px 20px 20px 0;
                    width: 82%;

                    &.mb-p {
                        padding: 4px 20px 10px 0;
                    }
                }
            }

            .link-btn {
                border-radius: 100px;
                background: $C_BLACK;
                color: $C_WHITE;
                font-size: 1.2rem;
                line-height: 1em;
                padding: 7px 10px;
                display: inline-block;
                margin-left: 10px;
                text-align: center;

                @include mq-s() {
                    font-size: 1.1rem;
                    line-height: 1em;
                    padding: 8px;
                }

                @media only screen and (max-width: 370px) {
                    width: 100%;
                    margin-left: 0;
                }
            }
        }
    }

    .contact-text {
        padding: 0 25px;

        @include mq-m() {
            padding: 0 15px;
        }
        @include mq-s() {
            padding: 0 10px;
        }
    }

    .attention-text {
        width: 100%;
        height: 300px;
        overflow: hidden;
        overflow-y: scroll;
        padding: 15px 20px;
        background: #f6f6f6;
        border-radius: 5px;
        box-shadow: 0 0 20px #ddd inset;
        margin-bottom: 40px;

        p {
            margin-bottom: 15px;
        }
    }

    &_link {
        text-align: center;
        @include clearfix;

        li {
            display: inline-block;
            min-width: 320px;
            padding: 0 10px;
            vertical-align: top;

            @include mq-m() {
                display: block;
                float: left;
                min-width: auto;
                width: 50%;

                &:nth-child(2n+1) {
                    padding: 0 5px 0 0;
                }
                &:nth-child(2n) {
                    padding: 0 0 0 5px;
                }
            }

            @include mq-s() {
                display: block;
                width: 100%;
                min-width: auto;

                &:nth-child(2n+1) {
                    padding: 0 10px 15px;
                }
            }
        }
    }
}


.attention {
    color: $C_RED;
    font-size: 0.85em;
}


.result-box {
    width: 100%;
    margin: 10px auto 30px;
    border-radius: 6px;
    border: 1px solid #ccc;
    display: table;
    vertical-align: middle;
    table-layout: fixed;

    @include mq-m() {
        display: block;
    }
    @include mq-s() {
        display: block;
    }

    dt {
        display: table-cell;
        vertical-align: middle;
        width: 30%;
        padding: 20px;
        border-right: 1px solid #ccc;
        text-align: center;
        font-size: 1.5rem;
        line-height: 1.25em;
        background: #f6f6f6;
        border-radius: 5px 0 0 5px;

        @include mq-m() {
            display: block;
            width: 100%;
            border-right: 0;
            border-bottom: 1px solid #ccc;
            border-radius: 5px 5px 0 0;
            padding: 10px 15px;
            text-align: left;
        }
        @include mq-s() {
            display: block;
            width: 100%;
            border-right: 0;
            border-bottom: 1px solid #ccc;
            border-radius: 5px 5px 0 0;
            padding: 10px 15px;
            text-align: left;
        }
    }
    dd {
        display: table-cell;
        vertical-align: middle;
        width: 70%;
        padding: 20px;
        font-family: $EN_FONT_FAMILY;
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1em;
        letter-spacing: 0.1em;

        @include mq-m() {
            display: block;
            width: 100%;
            padding: 20px 15px;
        }
        @include mq-s() {
            display: block;
            width: 100%;
            padding: 20px 15px;
        }
    }
}



/* ========================================
    form
======================================== */
.form-box {
    border: 0;
    box-shadow: none;
    border: #ccc 1px solid;
    padding: 5px;
    border-radius: 5px;
    font-size: 1.6rem;
    line-height: 1.5em;

    &_w20 {
        width: 20%;

        @media only screen and (max-width: 370px) {
            width: 30%;
            margin-bottom: 10px;
        }
    }
    &_w30 {
        width: 30%;

        @media only screen and (max-width: 370px) {
            width: 50%;
            margin-bottom: 10px;
        }
    }
    &_w50 {
        width: 50%;

        @include mq-m() {
            width: 100%;
        }
        @include mq-s() {
            width: 100%;
        }
    }
    &_w80 {
        width: 80%;

        @include mq-m() {
            width: 100%;
        }
        @include mq-s() {
            width: 100%;
        }
    }
    &_w100 {
        width: 100%;
    }
    &_text {
        width: 100%;
        height: 150px;
    }
}

/* ========================================
    radio
======================================== */
.radio-btn {
    @include clearfix;

    li {
        display: inline-block;
        min-width: 20%;
        padding-right: 10px;

        @include mq-m() {
            display: block;
            float: left;
            width: 50%;
            
            &:nth-child(2n+1) {
                padding-right: 5px;
            }
            &:nth-child(2n) {
                padding-right: 0;
                padding-left: 5px;
            }
        }
        @include mq-s() {
            display: block;
            float: left;
            width: 50%;
            
            &:nth-child(2n+1) {
                padding-right: 5px;
            }
            &:nth-child(2n) {
                padding-right: 0;
                padding-left: 5px;
            }
        }
    }

    label {
        position: relative;
    }
    
    input[type="radio"] ,
    input[type="checkbox"] {
        display: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        position: absolute;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .button {
        z-index: 1;
        display: block;
        width: 100%;
        padding: 4px 10px;
        border-radius: 100px;
        text-align: center;
        color: $C_BLACK;
        background-color: $C_WHITE;
        border: 1px solid $C_BLACK;
        cursor: pointer;
        transition: 0.3s;

        &.hover {
            background-color: $C_BLACK;
            color: $C_WHITE;
        }
    
        &:disabled {
            opacity: 0.5;
        }
    }
    :checked + .button {
        background-color: $C_BLACK;
        color: $C_WHITE;
    }
    :disabled + .button {
        opacity: 0.5;
    }
}

//appearance: none; for IE11
_:-ms-lang(x)::-ms-backdrop, .radio-btn [type=radio],
_:-ms-lang(x)::-ms-backdrop, .radio-btn [type=checkbox] {
    visibility: hidden;
}

select::-ms-expand {
    display: none;
}


/* ========================================
    select
======================================== */
.select-btn {
    display: inline-block;
    position: relative;

    @include mq-m() {
        width: 35%;

        &.mb-p1 {
            width: 55%;
            margin-bottom: 10px;
        }
        &.mb-p2 {
            width: 100%;
        }
    }
    @include mq-s() {
        width: 35%;

        &.mb-p1 {
            width: 55%;
            margin-bottom: 10px;
        }
        &.mb-p2 {
            width: 100%;
        }
    }

    &:after {
        content: '';
        width: 4px;
        height: 4px;
        border: 0px;
        border-bottom: solid 2px $C_BLACK;
        border-right: solid 2px $C_BLACK;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: calc(50% - 4px);
        right: 10px;
        z-index: 999;
    }

    .select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        background: $C_WHITE;
        position: relative;
        z-index: 1;
        padding: 0 30px 0 15px;
        border-radius: 5px;
        border: #ccc 1px solid;
        color: $C_BLACK;
        font-size: 1.6rem;
        line-height: 1.72em;
    }

    &_text {
        display: inline-block;
        padding: 0 15px 0 2px;
    }
}